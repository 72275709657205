import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, Outlet } from "react-router-dom";
import Cookies from "universal-cookie";

const AuthLayouts = () => {
  const url = window.location.pathname;
  const user = useSelector((state) => state.user);
  const [redirect, setRedirect] = useState(false);

  const [title, setTitle] = useState("پنل کاربری مدیر محتوا");
  const [urlAddress, setUrlAddress] = useState("#");
  const [anchorText, setAnchorText] = useState("");
  const cookies = new Cookies();

  useEffect(() => {
    protect();
    if (url === "/auth/login") {
      setTitle("ورود به پنل کاربری");
      setUrlAddress("/auth/sign-up");
      setAnchorText("حساب کاربری ندارید؟");
    } else if (url === "/auth/sign-up") {
      setTitle("ثبت نام در مدیر محتوا");
      setUrlAddress("/auth/login");
      setAnchorText("حساب کاربری دارید؟");
    } else if (url === "/auth/forget-password") {
      setTitle("بازنشانی رمز عبور");
      setUrlAddress("/auth/login");
      setAnchorText("حساب کاربری دارید؟");
    }
  }, [url]);

  const protect = () => {
    if (user.token !== "") {
      setRedirect(true);
    } else {
      if (cookies.get("auth")) {
        setRedirect(true);
      }
    }
  };
  
  return (
    <>
      {redirect ? (
        <Navigate to="/" />
      ) : (
        <div className="container h-[100vh]  overflow-auto  flex justify-center items-center">
          <div className="grid grid-cols-1 lg:grid-cols-2  h-[36rem]  lg:max-w-screen-lg w-full p-4 gap-4 relative">
            <div className="col-span-1 absolute w-full opacity-20 h-full flex justify-center items-center lg:static lg:opacity-100  ">
              <img
                src="/img/auth-image.webp"
                alt=""
                className="w-[60%] lg:w-[70%] m-auto"
              />
            </div>
            <div className="col-span-1 bg-[#ffffff8d] lg:bg-white justify-around px-4 py-4 h-full flex flex-col gap-12 rounded shadow-regular z-10 backdrop-blur-[2px] relative">
              <div className="">
                <h1 className="bg-gradient-to-r text-center to-title  from-project-cyan font-bold mb-4  text-transparent bg-clip-text text-xl sm:text-2xl md:text-3xl lg:text-4xl ">
                  {title}
                </h1>
                <hr className=" opacity-50" />
              </div>
              <Outlet />
              <div className="flex flex-col gap-4  items-center">
                <div className="flex gap-4  items-center">
                  <Link to="/auth/forget-password" className="text-title">
                    فراموشی رمز عبور
                  </Link>
                  |{" "}
                  <Link to={urlAddress} className="text-title">
                    {anchorText}
                  </Link>
                </div>

                <p className="text-primary text-[10px] ">
                  powerd by{" "}
                  <Link
                    to="tel:+989119339305"
                    className="text-title font-bold uppercase"
                  >
                    {" "}
                    mohammad bashardoost{" "}
                  </Link>{" "}
                  ©
                </p>
              </div>{" "}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default AuthLayouts;
