import { useContext } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ViewContext } from "../../context/ViewContext";

const DesktopBar = () => {
  const { toggle, setToggle } = useContext(ViewContext);
  const user = useSelector((state) => state.user);
  const products = useSelector((state) => state.card.products);

  return (
    <div
      className={` w-full ${toggle?"pr-[100px] ":"pr-[225px]"} transition-all duration-[.3s] top-0 leading-4  ease-in-out left-0 hidden md:flex fixed z-20 `}
    >
      <div className="flex  w-full justify-between  bg-[#f7fafd]  backdrop-blur-sm p-4 rounded-b">
      <button
        onClick={(e) => {
          e.preventDefault();
          setToggle(!toggle);
        }}
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          strokeWidth={1.5}
          stroke="currentColor"
          className="w-6 h-6 transition-all duration-[.5s] ease-in-out  bg-white rounded-full"
        >
          {toggle ? (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
            />
          ) : (
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15 15l6-6m0 0l-6-6m6 6H9a6 6 0 000 12h3"
            />
          )}
        </svg>
      </button>

      <div className="flex gap-4">
        <Link to="/card"
          title="سبد خرید"
          className="px-[15px] py-[5px] bg-white rounded relative"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 10.5V6a3.75 3.75 0 10-7.5 0v4.5m11.356-1.993l1.263 12c.07.665-.45 1.243-1.119 1.243H4.25a1.125 1.125 0 01-1.12-1.243l1.264-12A1.125 1.125 0 015.513 7.5h12.974c.576 0 1.059.435 1.119 1.007zM8.625 10.5a.375.375 0 11-.75 0 .375.375 0 01.75 0zm7.5 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z"
            />
          </svg>
          <p className="bg-project-orange absolute px-[7px] bottom-[4px] pt-[2px] right-2 text-[10px] text-white rounded-full">
            {products.length}
          </p>
        </Link >
        <button
          title="حساب کاربری"
          className="flex items-center gap-2 p-[5px] bg-white rounded"
        >
          {console.log(user.photo)}
          <img
            src={
              user.photo !== "user.png" && user.photo!==""
                ? `https://api.modirecontent.com/img/users/${user.photo}`
                : "/img/profile-placeholder.jpg"
            }
            alt=""
            className="h-6 w-6 rounded-full"
          />
          <div className="text-right">
            <p className="text-primary text-[12px]">{user.name}</p>
            <p className="text-project-orange text-[10px]">خوش آمدید</p>
          </div>
        </button>
      </div>
      </div>
    </div>
  );
};

export default DesktopBar;
