import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { store } from "../../app/store";
import { setUser } from "../../features/user/userSlice";
import {
  UpdateMe,
  UpdatePassword,
  UploadPhoto,
} from "../../services/userServices";
import Spinner from "../../spinner/Spinner";
import Cookies from "universal-cookie";

const Profile = () => {
  const cookies = new Cookies();
  const [file, setFile] = useState({});

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [website, setWebsite] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    setName(user.name);
    if (user.website) setWebsite(user.website);
    if (user.email) setEmail(user.email);
  }, [user]);

  const updatePassword = async (e) => {
    e.preventDefault();
    if (loading) return;
    setLoading(true);

    try {
      const formdata = new FormData();
      formdata.append("password", password);
      formdata.append("passwordConfirm", passwordConfirm);
      formdata.append("currentPassword", currentPassword);
      const token = user.token;
      const { data } = await UpdatePassword(token, formdata);

      store.dispatch(
        setUser({
          name: data.user.name || data.user.phoneNumber,
          token: data.token,
          photo: data.user.photo,
          role: data.user.role,
          id: data.user._id,
        })
      );
      cookies.set("auth", data.token, {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });
      setLoading(false);
      setPassword("");
      setPasswordConfirm("");
      setCurrentPassword("");
      toast.success("پسورد شما به روز شد");
    } catch (error) {
      setLoading(false);
      if (error.response.status == 401) {
        return toast.error("لطفا برای دسترسی مجددا وارد اکانت شوید.");
      }
    }
    // set redux
  };

  const updateInfo = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    try {
      formdata.append("name", name);
      formdata.append("website", website);
      formdata.append("email", email);
      const token = user.token;
      const { data } = await UpdateMe(token, formdata);
      store.dispatch(
        setUser({
          name: data.user.name || data.user.phoneNumber,
          token: cookies.get("auth"),
          photo: data.user.photo,
          role: data.user.role,
          id: data.user._id,
        })
      );
      cookies.set("auth", data.token, {
        path: "/",
        expires: new Date(Date.now() + 2592000),
      });

      setName(user.name);
      setWebsite(user.website);
      setLoading(false);
      toast.success("مشخصات شما به روز رسانی شد ");
    } catch (error) {
      setLoading(false);
      if (error.response.status == 401) {
        return toast.error("لطفا برای دسترسی مجددا وارد اکانت شوید.");
      }
    }
  };

  const uploadUserPhoto = async () => {
    if (loading) return;
    const formdata = new FormData();
    formdata.append("file", file);
    const token = user.token;
    try {
      setLoading(true);
      const { data } = await UploadPhoto(token, formdata);
      toast.success("عکس پروفایل آپلود شد");
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.status == 401) {
        return toast.error("لطفا برای دسترسی مجددا وارد اکانت شوید.");
      }
    }
  };

  const fileChangeHandler = (e) => {
    setFile(e.target.files[0]);
  };
  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  return (
    <div className="flex px-4 md:px-20 md:py-12  py-32  gap-8 flex-col relative">
      {loading ? (
        <div className="absolute w-full h-full backdrop-blur-lg flex items-center justify-center gap-4 text-project-cyan  text-4xl z-20">
          لطفا کمی صبر کنید
          <Spinner />
        </div>
      ) : (
        ""
      )}
      <div className="bg-white rounded w-full p-4 flex flex-col gap-4">
        <h2 className="text-primary text-base md:text-lg ">مشخصات شخصی</h2>
        <hr className="opacity-50" />
        <div className="flex gap-4 items-center flex-col md:flex-row">
          <label
            htmlFor=""
            className="md:w-[200px] text-right md:text-base text-sm"
          >
            نام و نام خانوادگی:
          </label>
          <input
            placeholder={name}
            onChange={(e) => {
              onChangeHandler(e, setName);
            }}
            type="text"
            className="border-none outline-none bg-background rounded w-full p-2"
          />
        </div>
        <div className="flex gap-4 items-center flex-col md:flex-row">
          <label
            htmlFor=""
            className="md:w-[200px] text-right md:text-base text-sm"
          >
            نام سایت:
          </label>
          <input
            placeholder={website}
            onChange={(e) => {
              onChangeHandler(e, setWebsite);
            }}
            type="text"
            className="border-none outline-none bg-background rounded w-full p-2"
          />
        </div>
        <div className="flex gap-4 items-center flex-col md:flex-row">
          <label
            htmlFor=""
            className="md:w-[200px] text-right md:text-base text-sm"
          >
            آدرس ایمیل
          </label>
          <input
            placeholder={email}
            onChange={(e) => {
              onChangeHandler(e, setEmail);
            }}
            type="text"
            className="border-none outline-none bg-background rounded w-full p-2"
          />
        </div>
        <button
          onClick={updateInfo}
          className="flex gap-4 items-center justify-center mt-4 bg-project-orange rounded text-white px-2 py-1 md:w-[max-content] mx-auto"
        >
          <p className="pt-1">ویرایش مشخصات</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </button>
      </div>
      <div className="bg-white rounded w-full p-4 flex flex-col gap-4">
        <h2 className="text-primary text-base md:text-lg ">مشخصات شخصی</h2>
        <hr className="opacity-50" />
        <div className="flex gap-4 items-center flex-col md:flex-row">
          <label
            htmlFor=""
            className="md:w-[200px] text-right md:text-base text-sm"
          >
            آپلود عکس پروفایل
          </label>
          <div className="flex w-full overflow-hidden rounded">
            <input
              type="file"
              onChange={fileChangeHandler}
              className="border-none outline-none bg-background  w-full p-2"
            />
            <button
              className=" bg-project-green  text-white p-2 "
              onClick={uploadUserPhoto}
            >
              <p className="pt-1">آپلود</p>
            </button>
          </div>
        </div>
      </div>
      <div className="bg-white rounded w-full p-4 flex flex-col gap-4">
        <h2 className="text-primary text-base md:text-lg ">
          {" "}
          بروز رسانی رمز عبور
        </h2>
        <hr className="opacity-50" />
        <div className="flex gap-4 items-center flex-col md:flex-row">
          <label
            htmlFor=""
            className="md:w-[200px] text-right md:text-base text-sm"
          >
            رمز عبور فعلی
          </label>
          <input
            value={currentPassword}
            onChange={(e) => {
              onChangeHandler(e, setCurrentPassword);
            }}
            type="text"
            className="border-none outline-none bg-background rounded w-full p-2"
          />
        </div>
        <div className="flex gap-4 items-center flex-col md:flex-row">
          <label
            htmlFor=""
            className="md:w-[200px] text-right md:text-base text-sm"
          >
            رمز عبور جدید
          </label>
          <input
            value={password}
            onChange={(e) => {
              onChangeHandler(e, setPassword);
            }}
            type="text"
            className="border-none outline-none bg-background rounded w-full p-2"
          />
        </div>
        <div className="flex gap-4 items-center flex-col md:flex-row">
          <label
            htmlFor=""
            className="md:w-[200px] text-right md:text-base text-sm"
          >
            تکرار رمز عبور جدید
          </label>
          <input
            value={passwordConfirm}
            onChange={(e) => {
              onChangeHandler(e, setPasswordConfirm);
            }}
            type="text"
            className="border-none outline-none bg-background rounded w-full p-2"
          />
        </div>
        <button
          onClick={updatePassword}
          className="flex gap-4 items-center justify-center mt-4 bg-project-orange rounded text-white px-2 py-1 md:w-[max-content] mx-auto"
        >
          <p className="pt-1"> بروز رسانی رمز عبور</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default Profile;
