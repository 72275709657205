import moment from "moment-jalaali";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { myOrders } from "../../services/shopServices";
import Spinner from "../../spinner/Spinner";
import Pagination from "../includes/Pagination";
import Order from "./Order";

const Orders = () => {
  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [results, setResults] = useState(0);
  const [payedAmount, setPayedAmount] = useState(0);
  const [order, setOrder] = useState({});
  const [modal,setModal] = useState(false)
  useEffect(() => {
    getOrderHandeler();
  }, [user]);

  const getOrderHandeler = async () => {
    try {
      const query = `page=${page}`;
      const token = user.token;
      if (!token) return;
      const { data } = await myOrders(token, query);
      setResults(data.results);
      setOrders(data.payments);
      
      if (data.PayedAmount[0]) setPayedAmount(data.PayedAmount[0].payed);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const dateConverter = (date) => {
    const m = moment(date, "YYYY/M/D HH:mm").format("jYYYY/jM/jD ساعت: HH:mm");
    // const d = new Date(date)
    return `${m}`;
  };

  return (
    <div
      div
      className="container px-4 md:px-20 md:py-12  py-32 flex flex-col items-center relative"
    >
      {loading?<Spinner/>:
      (<>
       {modal?(
           <Order order={order} setModal={setModal}/>
        ):""}
      <div className={`mt-10 w-full ${loading || modal ? "blur-sm" : ""}`}>
        <div className="hidden lg:grid grid-cols-7 items-center p-2 rounded  gap-2 bg-white mb-4 ">
          <div className="col-span-2 text-center">
            <p className="text-sm lg:text-base">تاریخ خرید</p>
          </div>
          <div className="col-span-2 text-center">
            <p>مبلغ کل</p>
          </div>
          <div className="col-span-1 text-center">
            <p>مبلغ پرداختی</p>
          </div>
          <div className="col-span-1 text-center">
            <p>وضعیت</p>
          </div>
          <div className="col-span-1 text-center">
            <p>عملیات‌ها</p>
          </div>
        </div>

        <>
          {orders.length > 0 ? (
            <div className="flex flex-col w-full gap-4">
              {/* loop */}
              {orders.map((el) => (
                <div className="grid grid-cols-8 lg:grid-cols-7 items-center bg-white p-2 rounded  gap-4  justify-center">
                  <div className="col-span-8  lg:col-span-2 flex justify-center gap-4">
                    <p className="lg:hidden w-[150px]">تاریخ خرید :</p>
                    <p className="px-2 py-1 bg-project-green bg-opacity-40 text-project-green lg:text-title lg:bg-transparent rounded w-full text-center">
                      {dateConverter(el.createdAt)}
                    </p>
                  </div>
                  <div className="col-span-8  lg:col-span-2 flex justify-center gap-4 mb-4 lg:mb-0 ">
                    <p className="lg:hidden w-[150px]">مبلغ کل :</p>
                    <p className="px-2 py-1 bg-project-cyan bg-opacity-25 text-project-cyan rounded w-full text-center">
                      {el.amount}
                    </p>
                  </div>
                  <div className="col-span-8 sm:col-span-4 justify-start sm:justify-center   lg:col-span-1 text-center flex items-center gap-4 ">
                    <p className="lg:hidden w-[200px] text-right">
                      مبلغ پرداختی:
                    </p>
                    <p className="text-left w-full sm:text-center">
                      {el.payedAmount}
                    </p>
                  </div>
                  <div className="col-span-8 sm:col-span-4 justify-start sm:justify-center   lg:col-span-1 text-center flex items-center gap-4   ">
                    <p className="lg:hidden  w-[150px] text-right">وضعیت:</p>
                    <p className="text-left w-full sm:text-center">
                      {el.status === 100 || el.status === 101
                        ? "پرداخت موفق"
                        : "پرداخت ناموفق"}
                    </p>
                  </div>

                  <div className="col-span-8 lg:col-span-1 flex gap-1">
                    <button
                    onClick={()=>{
                        setOrder(el.product)
                        setModal(true)
                    }}
                      disabled={loading || modal ? true : false}
                      className="bg-project-green w-full p-1 rounded flex justify-center text-white items-center gap-2"
                    >
                      مشاهده
                    </button>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <p className="w-full text-center">سفارشی یافت نشد</p>
          )}
        </>
      </div>
      <Pagination page={page} limit={10} results={results} setPage={setPage} loading={loading}/>
      </>)
      }
       
    </div>
  );
};

export default Orders;
