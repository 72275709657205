import axios from "axios";
const SERVER_URL = "https://api.modirecontent.com/api/v1";
// const SERVER_URL = "http://127.0.0.1:5000/api/v1";

export const getProducts = (token, data) => {
  const url = `${SERVER_URL}/product?${data}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios.get(url);
};
export const getProduct = (token, slug) => {
  const url = `${SERVER_URL}/product/${slug}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.get(url);
};

export const handlePayment = (token, data) => {
  const url = `${SERVER_URL}/shop/buy`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.post(url, data);
};
export const verifyPayment = (token, authority) => {
  const url = `${SERVER_URL}/shop/check-payment?authority=${authority}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios.get(url);
};
export const myOrders = (token,query) => {
  const url = `${SERVER_URL}/payment?${query}`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.get(url);
};

export const manual_order = (data,token) => {
  const url = `${SERVER_URL}/shop/manual-order`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios.post(url,data);
};