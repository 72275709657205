import { useEffect } from "react";
import { useState } from "react";
import { toast } from "react-toastify";
import { getProducts, manual_order } from "../../services/shopServices";
import { v4 as uuidv4 } from "uuid";
import { checkDiscount } from "../../services/discountServices";
import { Link } from "react-router-dom";
import { getAllUser } from "../../services/userServices";
import { useSelector } from "react-redux";

const ManualOrder = () => {
  const [card, setCard] = useState([]);
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [discount, setDiscount] = useState("");
  const [discountinput, setDiscountInput] = useState(false);
  const caculator = document.getElementById("caculator");
  const [forceUpdate, setForceUpdate] = useState(false);
  const [amountAll, setAmountAll] = useState(0);
  const [finallPrice, setFinallPrice] = useState(0);
  const [discountData, setDiscountData] = useState({});
  const [prodName, setProdName] = useState("");
  const [customerSearch, setCustomerSearch] = useState("");
  const [users, setUsers] = useState([]);

  const [customer, setCustomer] = useState([]);
  const [page, setPage] = useState(1);

  const [file, setFile] = useState([]);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    shopProducts();
  }, [forceUpdate, page]);

  const fileChangeHandler = (e) => {
    setFile(e.target.files[0]);
    console.log("file", file);
  };

  const shopProducts = async () => {
    try {
      let query = `page=${page}`;
      if (prodName != "") query = query + `&name=${prodName}`;

      const token = user.token;
      if (!token) return toast.error("خطایی رخ داده است");

      const { data } = await getProducts(token, query);
      setProducts(data.products);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const productFilterHandler = (e) => {
    e.preventDefault();

    setForceUpdate(!forceUpdate);
  };

  const deleteFromCard = async (e, id) => {
    e.preventDefault();
    if (card.length <= 0) {
      setAmountAll(0);
      setFinallPrice(0);
      return;
    }
    for (let i = 0; i < card.length; i++) {
      if (card[i].id === id) {
        const cardCopy = [...card];
        cardCopy.splice(i, 1);
        await setCardhandler(cardCopy);
        caculator.click();
      }
    }
  };
  const addToCard = async (e, slug, name, price, category, id) => {
    e.preventDefault();
    let amount = 0;
    const count = Number(document.getElementById(`${slug}+count`).value);
    const wordCount = Number(
      document.getElementById(`${slug}+wordCount`).value
    );
    const updateCard = [...card];
    switch (category) {
      case "content":
        amount = price * count * wordCount;
        break;
      default:
        amount = price;
        break;
    }
    updateCard.push({
      id: uuidv4(),
      product: category,
      count: count,
      wordCount: wordCount,
      name,
      price: Number(price),
      amount: Number(amount),
      category,
      product_id: id,
    });
    await setCardhandler(updateCard);
    caculator.click();
    document.getElementById(`${slug}+wordCount`).value = "";
    document.getElementById(`${slug}+count`).value = "";
  };
  const setCardhandler = async (data) => {
    setCard(data);
  };
  const prodChangeHandler = async (e, id, input) => {
    const cardCopy = [...card];
    const newCard = [];
    cardCopy.map((el) => {
      if (el.id === id)
        if (input === "wordCount") el.wordCount = Number(e.target.value);
        else {
          el.count = Number(e.target.value);
        }

      switch (el.category) {
        case "content":
          el.amount = el.price * el.count * el.wordCount;
          break;
        default:
          el.amount = el.price;
          break;
      }

      newCard.push(el);
    });
    await setCardhandler(newCard);

    caculator.click();
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const discountHandler = async (e) => {
    e.preventDefault();
    try {
      let mustPayAmount = 0;
      if (discount.trim() !== "") {
        const data = await checkDiscount(discount, card);
        if (data.status == 200) {
          setDiscountInput(true);
          setDiscountData(data.data);
          if (
            data.data.discount.maxDiscount >=
            (amountAll * data.data.discount.percent) / 100
          ) {
            mustPayAmount =
              amountAll - (amountAll * data.data.discount.percent) / 100;

            setFinallPrice(mustPayAmount);
          } else if (
            data.data.discount.maxDiscount <
            (amountAll * data.data.discount.percent) / 100
          ) {
            mustPayAmount = amountAll - data.data.discount.maxDiscount;

            setFinallPrice(mustPayAmount);
          }
        }
      }
    } catch (error) {
      return toast.error(error.response.data.message);
    }
  };

  const deleteDiscount = async (e) => {
    try {
      e.preventDefault();
      await setState(setDiscount, "");
      await setState(setDiscountInput, false);
      await setState(setDiscountData, {});
    } catch (err) {}

    caculator.click();
  };
  const setState = (state, data) => {
    state(data);
  };
  const amountHandeler = async (e) => {
    // e.preventDefault();
    let amount = 0;
    let mustPay = 0;

    card.map((el) => {
      switch (el.category) {
        case "content":
          amount = el.price * el.count * el.wordCount;
          break;
        default:
          amount = el.price;
          break;
      }
      mustPay = amount + mustPay;
    });
    setAmountAll(mustPay);
    let num = 0;
    if (discountData.discount) {
      if (
        discountData.discount.maxDiscount >=
        (mustPay * discountData.discount.percent) / 100
      ) {
        num = mustPay - (mustPay * discountData.discount.percent) / 100;
      } else if (
        discountData.discount.maxDiscount <
        (mustPay * discountData.discount.percent) / 100
      ) {
        num = mustPay - discountData.discount.maxDiscount;
      }
      return setFinallPrice(num);
    }
    setFinallPrice(mustPay);
  };

  const getUsers = async (e) => {
    e.preventDefault();

    let query = `&search=${customerSearch}&fields=name,_id,phoneNumber&limit=100`;

    const token = user.token;
    if (!token) return toast.error("خطایی رخ داده است.");

    const { data } = await getAllUser(1, query, token);
    console.log(data.doc);
    setUsers(data.doc);
  };
  const submitHandeler = async (e) => {
    e.preventDefault();
    const formdata = new FormData();

    try {
      formdata.append("file", file);
      formdata.append("discount_code", discount);
      formdata.append("customer", customer);
      formdata.append("prodList", JSON.stringify(card));
      const token = user.token;
      if (!token) return toast.error("خطایی رخ داده است");
      const { data } = await manual_order(formdata, token);
      toast.success("سفارش ثبت شد");
      setCard([]);
      setDiscount("");
      setDiscountData({});
      setDiscountInput(false);
      setAmountAll(0);
      setFinallPrice(0);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <div className="container px-4 md:px-20 md:py-6 py-32 relative flex flex-col ">
      <div className="flex flex-col gap-2 mb-4">
        <ul className="flex gap-1 text-primary">
          <li>
            <Link to="/">خانه</Link>
          </li>
          /
          <li>
            <Link to="/shop">فروشگاه</Link>
          </li>
        </ul>
        <h1 className="text-right text-3xl text-title">
          <span>فروشگاه </span>
          <span className="text-project-orange">مدیر محتوا </span>
        </h1>
      </div>
      <div className="grid grid-cols-4 gap-y-4 gap-x-2 ">
        <div className="col-span-4 lg:col-span-3 flex flex-col gap-4">
          <div className="flex ">
            <p className="w-[150px]">فیلتر محصولات:</p>
            <div className="flex w-full">
              <input
                onChange={(e) => onChangeHandler(e, setProdName)}
                type="text"
                placeholder="نام محصول را وارد کنید."
                className="p-2 w-full lg:w-auto xl:w-[300px] bg-white outline-none border-none rounded-r "
              />
              <button
                onClick={productFilterHandler}
                className="p-1 bg-project-red text-white rounded-l"
              >
                اعمال فیلتر
              </button>
            </div>
          </div>

          <div className="flex ">
            <p className="w-[150px]">جستجوی کاربر:</p>

            {users.length > 0 ? (
              <>
                <select
                  name=""
                  id=""
                  className="p-2 w-full lg:w-auto xl:w-[300px] bg-white outline-none border-none rounded-r "
                  onChange={(e) => {
                    onChangeHandler(e, setCustomer);
                  }}
                >
                  <option value="" disabled selected>
                    لطفا کاربر مدنظر را انتخاب کنید
                  </option>
                  {users.map((el) => (
                    <option value={el._id}>
                      {el.name ? el.name : el.phoneNumber}
                    </option>
                  ))}
                </select>
                <button
                  className="p-1 bg-project-red text-white rounded-l"
                  onClick={(e) => {
                    e.preventDefault();
                    setUsers([]);
                    setCustomer("");
                  }}
                >
                  جستجوی مجدد{" "}
                </button>
              </>
            ) : (
              <div className="flex w-full">
                <input
                  onChange={(e) => onChangeHandler(e, setCustomerSearch)}
                  type="text"
                  placeholder="نام یا شماره کارفرما را وارد کنید."
                  className="p-2 w-full lg:w-auto xl:w-[300px] bg-white outline-none border-none rounded-r "
                />
                <button
                  onClick={getUsers}
                  className="p-1 bg-project-red text-white rounded-l"
                >
                  اعمال فیلتر
                </button>
              </div>
            )}
          </div>
          <div className="flex ">
            <p className="w-[150px]">عکس رسید خرید:</p>

            <div className="flex">
              <input
               onChange={(e) => fileChangeHandler(e, setFile)}
                type="file"
                placeholder="نام یا شماره کارفرما را وارد کنید."
                className="p-2 w-full lg:w-auto xl:w-[300px] bg-white outline-none border-none rounded"
              />
            </div>
          </div>
          {/* map products */}
          {products.map((el) => (
            <div class=" flex flex-col gap-4 p-2 bg-white shadow-regular ">
              <div class=" flex-wrap flex  gap-4 justify-center sm:justify-start">
                <figure>
                  <img
                    src="https://api.modirecontent.com/uploads/2023/October/placeholder.png"
                    alt=""
                    class="max-w-[200px]"
                  />
                </figure>
                <div class="flex flex-col py-2 px-4 gap-4">
                  <h3 class="text-right text-primary text-xl font-bold">
                    {el.name}
                  </h3>
                  <p class="text-justify">{el.description}</p>
                </div>
              </div>
              <div class="grid grid-cols-6 justify-center sm:justify-between gap-y-4 gap-x-2">
                <div class="flex justify-between items-center col-span-6 md:col-span-3 xl:col-span-2">
                  <div class="flex items-center gap-2 w-full">
                    <p for="" className="w-[150px]">
                      تعداد:
                    </p>
                    <input
                      type="Number"
                      id={`${el.slug}+count`}
                      name=""
                      className="bg-gray-100 border-none outline-none p-1 w-full"
                    />
                  </div>
                </div>
                <div class="flex items-center gap-2 col-span-6 md:col-span-3 xl:col-span-2">
                  <p for="" className="w-[150px]">
                    تعداد کلمات:
                  </p>
                  <input
                    id={`${el.slug}+wordCount`}
                    type="Number"
                    name=""
                    className="bg-gray-100 border-none outline-none p-1 w-full"
                  />
                </div>
                <button
                  className="bg-green-500 col-span-6 xl:col-span-2 text-white rounded p-1 flex items-center justify-center gap-2 "
                  title="اضافه کردن به سبد خرید"
                  onClick={(e) =>
                    addToCard(
                      e,
                      el.slug,
                      el.name,
                      el.price,
                      el.category,
                      el._id
                    )
                  }
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="currentColor"
                    class="w-6 h-6"
                  >
                    <path
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      d="M2.25 3h1.386c.51 0 .955.343 1.087.835l.383 1.437M7.5 14.25a3 3 0 00-3 3h15.75m-12.75-3h11.218c1.121-2.3 2.1-4.684 2.924-7.138a60.114 60.114 0 00-16.536-1.84M7.5 14.25L5.106 5.272M6 20.25a.75.75 0 11-1.5 0 .75.75 0 011.5 0zm12.75 0a.75.75 0 11-1.5 0 .75.75 0 011.5 0z"
                    ></path>
                  </svg>
                  <p>اضافه کردن</p>
                </button>
              </div>
            </div>
          ))}

          {/* product map end */}
        </div>
        <div className="col-span-4 lg:col-span-3 xl:col-span-1 ">
          <div class="bg-white shadow-regular flex gap-6 flex-col py-2 px-1 rounded  w-full ">
            <p>لیست خرید</p>
            <hr className="opacity-50" />
            <div className="flex rounded p-1 overflow-hidden">
              <input
                type="text"
                placeholder="کد تخفیف"
                onChange={(e) => onChangeHandler(e, setDiscount)}
                className="p-1 bg-gray-100 w-full border-non outline-none rounded-r"
                value={discount}
              />
              {discountinput ? (
                <button
                  className="p-1 bg-project-red text-white"
                  onClick={deleteDiscount}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                    />
                  </svg>
                </button>
              ) : (
                <button
                  onClick={discountHandler}
                  className="p-1 bg-primary text-white"
                >
                  ثبت
                </button>
              )}
            </div>
            {card.map((el) => (
            <>
              <div className="p-1 flex gap-4 items-center">
                <div className="flex flex-col gap-2 w-full">
                  <div className="flex justify-between items-center ">
                    <p className="text-primary font-bold">{el.name}</p>
                    <p className="text-primary font-bold">تعرفه: {el.amount}</p>
                    <button
                      onClick={(e) => {
                        deleteFromCard(e, el.id);
                      }}
                      className="text-custom_red"
                      title="حذف محصول از سبد خرید"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="w-6 h-6"
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                        />
                      </svg>
                    </button>
                  </div>
                  <div className="flex justify-between items-center">
                    <label htmlFor="" className="text-right">
                      تعداد:
                    </label>
                    <input
                      onChange={(e) => prodChangeHandler(e, el.id, "count")}
                      value={el.count}
                      type="Number"
                      className="bg-gray-50 border-none outline-none px-2 "
                    />
                  </div>
                  <div className="flex justify-between items-center">
                    <label htmlFor="" className="text-right">
                      تعداد کلمات:
                    </label>
                    <input
                      onChange={(e) => prodChangeHandler(e, el.id, "wordCount")}
                      value={el.wordCount}
                      type="Number"
                      className="bg-gray-50 border-none outline-none px-2 "
                    />
                  </div>
                </div>
              </div>
              <hr className="opacity-50" />
            </>
          ))}
          {discountinput ? (
            <p className="text-xl line-through text-primary">
              هزینه کل : {amountAll} تومان{" "}
            </p>
          ) : (
            <></>
          )}
            <p className="text-xl text-primary"> پرداختی کاربر : {finallPrice} تومان</p>
            <button onClick={submitHandeler} className="bg-project-red p-1 text-white rounded ">
              ثبت سفارش
            </button>
            <button
            onClick={amountHandeler}
              id="caculator"
              className="bg-project-cyan p-1 text-white rounded "
            >
              محاسبه هزینه
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ManualOrder;
