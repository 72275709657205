import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";
import LogOut from "./components/auth/logout";
import SignUp from "./components/auth/SignUp";
import AccountManager from "./components/contents/AccountManager";
import Content from "./components/contents/Content";
import ContentEditor from "./components/contents/ContentEditor";
import ContentManager from "./components/contents/ContentManager";
import Contents from "./components/contents/Contents";
import ContentWriter from "./components/contents/ContentWriter";
import Designer from "./components/contents/Designer";
import DesignManager from "./components/contents/DesignManager";
import WithoutProjects from "./components/contents/WithoutProjects";
import Dashboard from "./components/Dashboard/Dashboard";
import NotAccess from "./components/errors/NotAccess";
import NotFound from "./components/errors/NotFound";
import AuthLayouts from "./components/Layouts/AuthLayouts";
import DashLayout from "./components/Layouts/DashLayout";
import AddPost from "./components/post/AddPost";
import EditPost from "./components/post/EditPost";
import Posts from "./components/post/Posts";
import Project from "./components/projects/Project";
import Projects from "./components/projects/Projects";
import Card from "./components/shop/Card";
import ManualOrder from "./components/shop/ManualOrder";
import Orders from "./components/shop/Orders";
import Product from "./components/shop/Product";
import Shop from "./components/shop/Shop";
import VerifyPayment from "./components/shop/VerifyPayment";
import Profile from "./components/users/Profile";
import FileManager from "./file/FileManager";
import FileMonthList from "./file/FileMonthList";
import Files from "./file/Files";
import FileYearList from "./file/FileYearList";
import Spinner from "./spinner/Spinner";

function App() {
  const user = useSelector((state) => state.user);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    protect();
  }, [user]);

  const protect = () => {
    console.log(window.location.pathname);
    if (window.location.pathname.startsWith("/auth")) return setLoading(false);
    if (window.location.pathname.startsWith("/logout")) return setLoading(false);
    if (window.location.pathname === "/") return setLoading(false);

    if (!user.token) return;

    return setLoading(false);
  };

  return (
    <div className="w-full ">
      {loading ? (
        <div className="absolute w-full h-full backdrop-blur-md z-20 ">
          <Spinner />
        </div>
      ) : (
        ""
      )}
      <BrowserRouter>
        <Routes>
          <Route path="/" Component={DashLayout}>
            <Route path="/" element={<Dashboard />} />
            <Route path="/profile" element={<Profile />} />

            <Route
              path="/files"
              element={
                user.role === "administrator" ? <FileManager /> : <NotAccess />
              }
            >
              <Route
                path="/files/"
                element={
                  user.role === "administrator" ? (
                    <FileYearList />
                  ) : (
                    <NotAccess />
                  )
                }
              />
              <Route
                path="/files/:year"
                element={
                  user.role === "administrator" ? (
                    <FileMonthList />
                  ) : (
                    <NotAccess />
                  )
                }
              />
              <Route
                path="/files/:year/:month"
                element={
                  user.role === "administrator" ? <Files /> : <NotAccess />
                }
              />
            </Route>

            <Route
              path="/posts"
              element={
                user.role === "administrator" ? <Posts /> : <NotAccess />
              }
            />
            <Route
              path="/posts/edit-post/:id"
              element={
                user.role === "administrator" ? <EditPost /> : <NotAccess />
              }
            />
            <Route
              path="/posts/add-new"
              element={
                user.role === "administrator" ? <AddPost /> : <NotAccess />
              }
            />

            <Route
              path="/orders"
              element={
                user.role === "administrator" ? <Orders /> : <NotAccess />
              }
            />

            <Route
              path="/shop"
              element={user.role === "customer" ? <Shop /> : <NotAccess />}
            />
            <Route
              path="/shop/:slug"
              element={user.role === "customer" ? <Product /> : <NotAccess />}
            />
            <Route
              path="/card"
              element={user.role === "customer" ? <Card /> : <NotAccess />}
            />
            <Route
              path="/shop/verify-payment"
              element={
                user.role === "customer" ? <VerifyPayment /> : <NotAccess />
              }
            />
            <Route
              path="/shop/manual-order"
              element={
                user.role === "administrator" ? <ManualOrder /> : <NotAccess />
              }
            />
            <Route
              path="/contents/"
              element={user.role === "customer" ? <Contents /> : <NotAccess />}
            />
            <Route
              path="/contents/without-project"
              element={
                user.role === "customer" ? <WithoutProjects /> : <NotAccess />
              }
            />
            <Route
              path="/contents/:id"
              element={
                user.role === "customer" ||
                user.role === "accountManager" ||
                user.role === "contentTeam" ||
                user.role === "designTeam" ? (
                  <Content />
                ) : (
                  <NotAccess />
                )
              }
            />
            <Route
              path="/content-writer/"
              element={
                user.role === "contentTeam" ? <ContentWriter /> : <NotAccess />
              }
            />
            <Route
              path="/content-editor/"
              element={
                user.role === "contentTeam" ? <ContentEditor /> : <NotAccess />
              }
            />
            <Route
              path="/content-manager/"
              element={
                user.role === "contentTeam" ? <ContentManager /> : <NotAccess />
              }
            />
            <Route
              path="/account-manager/"
              element={
                user.role === "accountManager" ? (
                  <AccountManager />
                ) : (
                  <NotAccess />
                )
              }
            />
            <Route
              path="/design-manager/"
              element={
                user.role === "designTeam" ? <DesignManager /> : <NotAccess />
              }
            />
            <Route
              path="/designer/"
              element={
                user.role === "designTeam" ? <Designer /> : <NotAccess />
              }
            />

            <Route
              path="/projects/"
              element={user.role === "customer" ? <Projects /> : <NotAccess />}
            />
            <Route
              path="/projects/:id"
              element={user.role === "customer" ? <Project /> : <NotAccess />}
            />
          </Route>
         
          <Route path="/auth" Component={AuthLayouts}>
            <Route path="/auth/login" Component={Login} />
            <Route path="/auth/sign-up" Component={SignUp} />
            <Route path="/auth/forget-password" Component={ForgotPassword} />
            
          </Route>
          {/* <Route path="/logout" Component={LogOut} /> */}
          <Route Route path="*" exact={true} Component={NotFound} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
