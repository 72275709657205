import { useContext } from "react";
import { ViewContext } from "../../context/ViewContext";

const MobileTop = () => {
  const { toggle, setToggle } = useContext(ViewContext);
  return (
    <div className=" bg-[#fff]  fixed w-full p-4 flex justify-center items-center md:hidden z-20" >
      <img src="/img/modirecontent.png" alt="" className="w-16 " />
      <svg
        xmlns="http://www.w3.org/2000/svg"
        onClick={() => {
          setToggle(!toggle);
        }}
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth={1.5}
        stroke="currentColor"
        className="w-6 h-6 fixed right-10 rounded-full cursor-pointer"
      >
        {toggle ? (
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3.75 5.25h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5m-16.5 4.5h16.5"
          />
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        )}
      </svg>
    </div>
  );
};

export default MobileTop;
