import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  statusHandler as contentStatusHandler,
  uploadContentFile,
  updateMany,
} from "../../../services/contentServices";

import Spinner from "../../../spinner/Spinner";

const ContentExpertDialog = ({ content, id, forceUpdate, role }) => {
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("لطفا منتظر بمانید");
  const user = useSelector((state) => state.user);
  const [status, setStatus] = useState("");
  useEffect(() => {
    setLoading(false);
  }, []);
  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const statusHandler = async (e) => {
    e.preventDefault();
    try {
      if (status === "") return toast.info("لطفا ابتدا یک وضعیت انتخاب کنید");
      const token = user.token;
      if (!token) return;

      const { data } = await updateMany(token, {
        updateState: role,
        contentsID: [{ _id: id }],
        update: {
          status: status,
        },
      });
      if(data.contents.n ==0){ toast.info("هیچ محتوایی آپدیت نشد")}else{
        toast.success(`محتوا آپدیت شد: ${data.contents.n} عدد`);
      }
     
      setStatus(status);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      {loading ? (
        <div className="absolute z-10 text-2xl">
          <Spinner />
          <p>{loadingText}</p>
        </div>
      ) : (
        ""
      )}
      <div
        className={`  grid grid-cols-12 gap-y-3 lg:gap-x-3 lg:gap-y-6 w-full ${
          loading ? "blur-md" : ""
        }`}
      >
        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col items-start sm:items-center sm:flex-row    ">
          <p className="w-[100px]">وضعیت :</p>
          <div className="flex w-full sm:w-[calc(100%-100px)] bg-background shadow-regular rounded overflow-hidden">
            <select
              onChange={(e) => {
                onChangeHandler(e, setStatus);
              }}
              name=""
              id=""
              className="outline-none border-none w-full p-2 rounded bg-background"
            >
              <option value="rewrite" disabled selected>
                گزینه مورد نظر را انتخاب کنید
              </option>{" "}
              {role === "writer" ? (
                <>
                  <option value="writting">در حال نگارش</option>
                  <option value="written">نگارش شده</option>
                </>
              ) : (
                <>
                  <option value="Editor Approved">تایید ویراستار</option>
                  <option value="Need rewriting">نیاز به بازنویسی</option>
                </>
              )}
            </select>
            <button
              className="bg-project-green text-white hover:animate-pulse  px-2 py-1 "
              onClick={statusHandler}
            >
              ثبت
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentExpertDialog;
