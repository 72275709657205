import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";

import { useSelector } from "react-redux";
import { store } from "../../app/store";
import {
  setCard,
  setStateDiscount,
  caculatePrice,
  caculateAmount,
} from "../../features/card/cardSlice";
import { checkDiscount } from "../../services/discountServices";
import { handlePayment } from "../../services/shopServices";

import Spinner from "../../spinner/Spinner";

const Card = () => {
  const cookies = new Cookies();

  const [discount, setDiscount] = useState("");
  const [discountinput, setDiscountInput] = useState(false);

  const card = useSelector((state) => state.card);
  const user = useSelector((state) => state.user);
  const [products, setProducts] = useState([]);
  const [submitLoading, setSubmitLoading] = useState(false);
  const [discountLoading, setDiscountLoading] = useState(false);

  useEffect(() => {
    if (card.discount.discount_code) {
      setDiscount(card.discount.discount_code);
      setDiscountInput(true);
    }

    caculateprice();
    setProducts(card.products);
  }, [card]);

  const discountHandler = async () => {
    if (discountLoading) return;
    try {
      if (discount.trim() !== "") {
        setDiscountLoading(true);
        const data = await checkDiscount(discount, products);
        if (data.status == 200) {
          setDiscountInput(true);
          toast.success("کد تخفیف شما ثبت شد");
          store.dispatch(
            setStateDiscount({
              discount_code: data.data.discount.discount_code,
              maxDiscount: data.data.discount.maxDiscount,
              percent: data.data.discount.percent,
            })
          );
          cookies.set("discount", {
            discount_code: data.data.discount.discount_code,
            maxDiscount: data.data.discount.maxDiscount,
            percent: data.data.discount.percent,
          });
        }
      }
      setDiscountLoading(false);
    } catch (error) {
      setDiscountLoading(false);
    
      return toast.error(error.response.data.message);
    }
  };
  const deleteDiscount = () => {
    if (cookies.get("discount")) {
      cookies.remove("discount");
    }
    if (discount !== "") {
      store.dispatch(setStateDiscount({}));
    }
    setDiscount("");
    setDiscountInput(false);
  };

  const removeItemById = (id) => {
    for (let i = 0; i < products.length; i++) {
     
      if (products[i].id === id) {
        const copyProduct = [...products];
        copyProduct.splice(i, 1);
        setProducts(copyProduct);
        cookies.set("card", copyProduct);
        store.dispatch(setCard(copyProduct));
      }
    }
  };

  const caculateprice = () => {
    store.dispatch(caculatePrice());
    store.dispatch(caculateAmount());
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
 
  };

  const paymentHandler = async () => {
    if (submitLoading) return;
    try {
      if (card.products.length <= 0) {
        return toast.info("سبد خرید شما خالی است");
      }
      const token = user.token;
      setSubmitLoading(true);
      const data = await handlePayment(token, {
        discount_code: card.discount.discount_code,
        prodList: card.products,
      });
      if (data.data.redirect) {
        setSubmitLoading(false);
        window.location.replace(data.data.redirect);
      } else {
        setSubmitLoading(false);

        toast.info("خطایی رخ داده است. در صورت تکرار با پشتیبانی تماس بگیرید");
      }
    } catch (error) {
     

      toast.error(error.response.data.message);
    }
  };
  return (
    <div className="container px-4 md:px-20 md:py-12  py-32">
      {/* tite / breadcrumb */}
      <div className="flex flex-col gap-2 mb-4">
        <ul className="flex gap-1 text-primary">
          <li>
            <Link to="/">خانه</Link>
          </li>
          /
          <li>
            <Link to="/card">سبد خرید</Link>
          </li>
        </ul>
        <h1 className="text-right text-3xl text-title font-bold">
          <span>سبد </span>
          <span className="text-project-orange">خرید شما</span>
        </h1>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-12 gap-4">
        {/* main items */}
        <div className="col-span-1 lg:col-span-8 ">
          <div className="flex flex-col w-full gap-4">
            <div className="sm:grid grid-cols-7 items-center p-2 rounded  gap-2 hidden">
              <div className="col-span-1 text-center">
                <p className="text-sm lg:text-base">عکس</p>
              </div>
              <div className="col-span-2 text-center">
                <p>نام محصول</p>
              </div>
              <div className="col-span-2 text-center">
                <p>تعداد کلمات</p>
              </div>
              <div className="col-span-1 text-center">
                <p>تعداد</p>
              </div>
              <div className="col-span-1 text-center">
                <p>عملیات‌ها</p>
              </div>
            </div>
          </div>
          <div className="flex flex-col w-full gap-4">
            {/* loop */}
            {card.products.map((el) => (
              <div className="grid grid-cols-7 items-center bg-white p-2 rounded shadow-regular gap-4">
                <div className="col-span-2 md:col-span-1  text-center">
                  <img src={el.img} alt="" className="w-[100px] rounded" />
                </div>
                <div className="col-span-5 md:col-span-2 text-center">
                  <p className="px-2 py-1 bg-project-cyan bg-opacity-40 text-project-cyan rounded ">
                    {el.name}
                  </p>
                </div>
                <div className="col-span-7 justify-center sm:justify-start sm:col-span-3 md:col-span-2 text-center flex items-center gap-1  md:justify-center">
                  <p className="md:hidden ">تعداد کلمات:</p>
                  <p>{el.wordCount}</p>
                </div>
                <div className="col-span-7 justify-center sm:justify-start  sm:col-span-3 md:col-span-1 text-center flex items-center gap-1   md:justify-center">
                  <p className="md:hidden">تعداد سفارش:</p>
                  <p>{el.count}</p>
                </div>
                <div className="col-span-7 sm:col-span-1 ">
                  {" "}
                  <button
                    className="bg-project-red w-full p-1 rounded flex justify-center text-white items-center gap-2"
                    onClick={() => removeItemById(el.id)}
                  >
                    <p className="sm:hidden">حذف محصول</p>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6 "
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                      />
                    </svg>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className="col-span-1 lg:col-span-4 p-4 flex flex-col gap-8 bg-[#fff] rounded shadow-regular h-[max-content]">
          <h2 className="text-center bg-gradient-to-r to-title  from-project-cyan font-bold  text-transparent bg-clip-text">
            {" "}
            صورت حساب شما
          </h2>
          <div className="flex items-center">
            <p className="w-[150px]">کد تخفیف:</p>
            <div className="flex bg-background rounded overflow-hidden w-full">
              <input
                type="text"
                onChange={(e) => onChangeHandler(e, setDiscount)}
                value={discount}
                readOnly={discountinput}
                className="p-1 w-full border-none outline-none bg-background text-alternative "
              />
              <>
                {discountLoading ? (
                  <button
                    className="py-1 px-2 bg-project-cyan bg-opacity-50 text-project-cyan font-bold"
                    disabled={true}
                  >
                    <Spinner />
                  </button>
                ) : (
                  <>
                    {card.discount.discount_code ? (
                      <button
                        className="py-1 px-2 bg-project-red bg-opacity-50 text-project-red font-bold"
                        onClick={deleteDiscount}
                        disabled={!discountinput}
                      >
                        حذف
                      </button>
                    ) : (
                      <button
                        className="py-1 px-2 bg-project-green bg-opacity-40 text-project-green font-bold"
                        onClick={discountHandler}
                        disabled={discountinput}
                      >
                        ثبت
                      </button>
                    )}
                  </>
                )}
              </>
            </div>
          </div>
          <div className="flex items-center">
            <p className="w-[150px]">هزینه کل:</p>
            <p
              className={`text-2xl ${
                card.discount.discount_code ? "line-through" : ""
              }`}
            >
              {card.price.toLocaleString()}
            </p>
          </div>
          <div className="flex items-center">
            <p className="w-[150px]">هزینه نهایی:</p>
            <p className="text-2xl">{card.amount.toLocaleString()}</p>
          </div>
          {/* <button className="bg-project-orange text-white p-1  rounded" onClick={caculateprice}>
            بروزرسانی هزینه
          </button> */}
          <button
            className="bg-project-cyan text-white p-1  rounded flex justify-center gap-2"
            onClick={paymentHandler}
          >
            {submitLoading ? <Spinner /> : ""}
            پرداخت
          </button>
        </div>
      </div>
    </div>
  );
};

export default Card;
