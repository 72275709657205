import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  getContent,
  getMessages,
  sendMessage,
} from "../../services/contentServices";
import Spinner from "../../spinner/Spinner";
import EditDialog from "./EditDialog";
import Message from "./includes/Message";

const Content = () => {
  const { id } = useParams() || "NullPostId";
  const user = useSelector((state) => state.user);

  const [messages, setMessages] = useState([]);

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  const [name, setName] = useState("");
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [subject, setSubject] = useState("");
  const [file, setFile] = useState({});
  const [project, setProject] = useState({});
  const [contentLink, setContentLink] = useState("");
  const [forceUpdate, setForceUpdate] = useState(false);

  const [content, setContent] = useState({
    name: "",
    keywords: "",
    description: "",
    status: "",
    subject: "",
    contentLink: "",
    project: {
      siteName: "",
    },
  });

  useEffect(() => {
    getContentHandler();
    getMessageHandler();
  }, [user, forceUpdate]);

  const getMessageHandler = async () => {
    try {
      const token = user.token;
      if (!token) return;
      const { data } = await getMessages(token, { contentID: id });
      setMessages(data.messages);

      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const sendMessageHandler = async (e) => {
    e.preventDefault();
    try {
      if (message.length < 10) {
        return toast.info("پیام شما حداقل باید 10 کارکتر داشته باشد");
      }
      const token = user.token;
      if (!token) return;
      const { data } = await sendMessage(token, { contentID: id, message });
      toast.success("پیام شما ارسال شد");
      setMessage("");
      getMessageHandler();
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const getContentHandler = async () => {
    try {
      const token = user.token;
      if (!token) return;

      const { data } = await getContent(token, id);

      setKeywords(data.content.keywords || "");
      setDescription(data.content.description || "");
      setStatus(data.content.status || "ordered");
      setSubject(data.content.subject);
      setContent({
        contentExpert: data.content.ContentExpert || { _id: "" },
        name: data.content.name || "",
        keywords: data.content.keywords || "",
        description: data.content.description || "",
        status: data.content.status || "",
        subject: data.content.subject || "",
        contentLink: data.content.contentLink || "",
        deadline: data.content.deadline || undefined,
        project: data.content.project || { siteName: "تعیین نشده است" },
      });
      console.log(data.content)
      setStatus(data.content.status);
      if (data.content.project) {
        setProject({
          name: data.content.project.name || "",
          id: data.content.project.id || "",
        });
      }
      setContentLink(data.content.contentLink || "");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const statusConverter = (status = { text: "", stage: "" }) => {
    console.log(content);
    switch (status.stage) {
      case "Pending Customer":
        if (status.text === "ordered") {
          return "در انتظار ارسال برای نگارش کارفرما";
        }
        if (status.text === "The content is ready") {
          return "در انتظار بررسی کارفرما";
        }
        break;
      case "Customer Approved":
        return "تایید کارفرما";

      case "Pending Account Manager":
        return "در انتظار اکانت منیجر";

      case "Pending Writer":
        if (status.text === "The content is currently being written") {
          return "در حال نگارش";
        }
        if (status.text === "The content requires rewriting") {
          return "نیاز به بازنویسی";
        }
        break;

      case "Pending Editor":
        if (status.text === "The content has been written") {
          return "نیار به بررسی ویراستار";
        }
        if (status.text === "The manager does not approve") {
          return "عدم تایید مدیر محتوا";
        }
        if (status.text === "Customer has not approved the content") {
          return "عدم تایید مشتری";
        }
        break;

      case "Pending Content Manager":
        if (status.text === "The content has been approved by the editor") {
          return "نیار به بررسی مدیر محتوا";
        }
        if (status.text === "The Account manager does not approve") {
          return "عدم تایید اکانت منیجر";
        }
      default:
        return "وضعیت تعیین نشده ";
    }
  };

  return (
    <div className="container px-4 md:px-20 md:py-6  py-32 relative flex justify-center  ">
      {loading ? (
        <div className="absolute z-10 text-2xl">
          <Spinner />
        </div>
      ) : (
        ""
      )}
      <div
        className={`grid grid-cols-12 w-full rounded-lg   px-2 py-4 ${
          loading ? "blur-lg" : ""
        }`}
      >
        <div className="col-span-12  lg:col-span-4 order-2 lg:order-1 h-[26rem] h-md:h-[40rem]  h-sm:h-[32rem] overflow-y-auto  xl:col-span-3  px-2 ">
          <div className="flex h-full justify-center gap-4  flex-col">
            <div className="flex flex-col items-start gap-2">
              <p className="w-[150px]  text-project-orange  rounded-full">
                موضوع محتوا:
              </p>
              <p className="bg-white p-1 rounded w-full">
                {content.subject ? content.subject : "موضوع تعیین نشده است"}
              </p>
            </div>

            <div className="flex flex-col items-start gap-2">
              <p className="w-[150px]  text-project-green  rounded-full">
                کلمات کلیدی محتوا:
              </p>
              <p className="bg-white p-1 rounded w-full">
                {content.keywords
                  ? content.subject
                  : "کلمات کلیدی تعیین نشده است"}
              </p>
            </div>
            <div className="flex flex-col items-start  gap-2">
              <p className="w-[150px]  text-project-red  rounded-full">
                آدرس سایت:
              </p>
              <p className="bg-white p-1 rounded w-full uppercase text-left">
                {content.project.siteName}
              </p>
            </div>
            <div className="flex flex-col items-start  gap-2">
              <p className="w-[150px]  text-alternative  rounded-full">
                وضعیت محتوا:‍
              </p>
              <p className="bg-project-cyan text-white p-1 rounded w-full text-center">
                {statusConverter(status)}
              </p>
            </div>
            <div className="flex flex-col items-start  gap-2 ">
              <p className="w-[150px]  text-project-cyan  rounded-full">
                فعالیت ها:
              </p>
              <div className="flex items-center justify-between gap-2 w-full">
                <Link
                  target={"_blank"}
                  to={`${
                    user.token && content.project._id
                      ? `http://127.0.0.1:5000/api/v1/projects/download-file/${content.project._id}?token=${user.token}`
                      : ``
                  }`}
                  className="bg-project-orange text-white  p-1 rounded w-full text-center"
                >
                  {" "}
                  فایل ساختار
                </Link>
                {content.contentLink ? (
                  <Link
                    target="_blank"
                    to={content.contentLink}
                    className="bg-project-green text-white  p-1 rounded w-full text-center"
                  >
                    {" "}
                    فایل محتوا
                  </Link>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="col-span-12 order-1 lg:order-2 lg:col-span-8 xl:col-span-9  h-[26rem] h-md:h-[40rem]  h-sm:h-[32rem]  p-2 flex flex-col shadow-regular rounded mx-2  bg-white ">
          <div className=" h-full flex flex-col p-4 overflow-y-auto gap-4">
            <div className="flex flex-col gap-1 items-center text-center">
              <div className=" w-full xl:w-[max-content] bg-project-cyan bg-opacity-10  p-4 rounded-2xl">
                <p className="max-w-md">
                  <strong>توضیحات پروژه: </strong>
                  {content.project.description
                    ? content.project.description
                    : "برای این پروژه توضیحاتی قرار نگرفته است"}
                </p>
              </div>
              <p className="text-primary text-sm p-1 opacity-80">admin</p>
            </div>
            <div className="flex flex-col gap-1 items-center text-center">
              <div className=" w-full xl:w-[max-content] bg-project-cyan bg-opacity-10  p-4 rounded-2xl">
                <p className="max-w-md">
                  <strong>توضیحات محتوا: </strong>{" "}
                  {content.description
                    ? content.description
                    : "برای محتوا توضیحاتی قرار نگرفته است"}
                </p>
              </div>
              <p className="text-primary text-sm p-1 opacity-80">admin</p>
            </div>

            {messages.map((el) => (
              <Message data={el} />
            ))}
          </div>
          <div className="bg-project-cyan bg-opacity-30 h-10 rounded flex items-center overflow-hidden mt-2 ">
            <input
              value={message}
              onChange={(e) => onChangeHandler(e, setMessage)}
              type="text"
              className="bg-transparent outline-none border-none w-full px-2 py-2"
            />
            <button
              onClick={sendMessageHandler}
              className="px-2  transition-all duration-[.3s] ease-in-out"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6 rotate-180"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6 12L3.269 3.126A59.768 59.768 0 0121.485 12 59.77 59.77 0 013.27 20.876L5.999 12zm0 0h7.5"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Content;
