import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import Cookies from "universal-cookie";
import { store } from "../../app/store";
import { setUser } from "../../features/user/userSlice";
import { getMe } from "../../services/authServices";
import { ViewContext } from "../../context/ViewContext";
import SideBar from "../includes/SideBar";
import DesktopBar from "../includes/DesktopBar";
import MobileTop from "../includes/MobileTop";
import MobileMenu from "../includes/MobileMenu";
import MobileBottom from "../includes/MobileBottom";
import { getinitialData } from "../../services/contentServices";
import { setContents } from "../../features/contents/contentSlice";

const DashLayout = () => {
  const cookies = new Cookies();
  const [redirect, setRedirect] = useState(false);
  const user = useSelector((state) => state.user);
  const [toggle, setToggle] = useState(true);

  useEffect(() => {
    protect();
  }, [redirect]);

  const protect = async () => {
    if (!user.token) {
      if (!cookies.get("auth")) {
        setRedirect(true);
        setUser({
          name: "",
          token: "",
          photo: "",
          role: "",
          id: "",
        });
        cookies.remove("auth");
      } else {
        try {
          const token = cookies.get("auth");
          const { data } = await getMe(token);

          store.dispatch(
            setUser({
              name: data.user.name || data.user.phoneNumber,
              token: cookies.get("auth"),
              photo: data.user.photo,
              role: data.user.role,
              id: data.user._id,
              email: data.user.email,
              website: data.user.website,
            })
          );
        } catch (error) {
          toast.error(error.response.data.message);
          setUser({
            name: "",
            token: "",
            photo: "",
            role: "",
            id: "",
            email: "",
            website: "",
          });
          cookies.remove("auth");
          setRedirect(true);
        }
      }
    } else {
      // const data = await getinitialData(user.token);
      // console.log(data);
      // toast.success("داده ها دریافت شدند.");
    }
  };

  return (
    <>
      <ViewContext.Provider value={{ toggle: toggle, setToggle: setToggle }}>
        {redirect ? <Navigate to="/auth/login" /> : ""}
        <div className=" h-[100vh] flex">
          {/* desktop view */}
          {/* SideBar */}

          <SideBar />
          {/* main part */}

          <div className={`w-full h-full overflow-y-auto md:py-6 relative`}>
            {/* DesktopBar */}
            <DesktopBar />

            {/* mobile view */}

            {/* top bar */}
            <MobileTop />

            {/* menu bar */}
            <MobileMenu />
            {/* main */}
            <div className="mt-10">
              <Outlet />
            </div>
            {/* bottom bar */}
            <MobileBottom />
          </div>
        </div>
      </ViewContext.Provider>
    </>
  );
};

export default DashLayout;
