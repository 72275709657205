import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import { updateMany } from "../../../services/contentServices";
import { GetUsers } from "../../../services/userServices";

import Spinner from "../../../spinner/Spinner";

const DesignTeamEditDialog = ({ content, id, forceUpdate, role }) => {
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("لطفا منتظر بمانید");
  const user = useSelector((state) => state.user);
  const [status, setStatus] = useState("");

  const [graphists, setGraphists] = useState([]);
  const [graphist, setGraphist] = useState("");
  const [graphistSearchInput, setGraphistSearchInput] = useState("");

  useEffect(() => {
    setLoading(false);
  }, []);
  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const searchUser = async (e) => {
    e.preventDefault();

    try {
      const token = user.token;
      if (!token) return;

      const { data } = await GetUsers(token, {
        name: graphistSearchInput,
        content: id,
        userRole: "designTeam",
      });
      setGraphists(data.user);

      //
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    const token = user.token;
    if (!token) return;
    try {
      setLoading(true);

      setLoadingText("در حال بروزرسانی محتوا");

      const { data } = await updateMany(token, {
        updateState: role,
        contentsID: [{ _id: id }],
        update: {
          imageStatus: status,
          designTeam: {
            designer: graphist,
          },
        },
      });

      forceUpdate();
      setLoading(false);
      toast.success("محتوا آپدیت شد");
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      {loading ? (
        <div className="absolute z-10 text-2xl">
          <Spinner />
          <p>{loadingText}</p>
        </div>
      ) : (
        ""
      )}
      <div
        className={`  grid grid-cols-12 gap-y-3 lg:gap-x-3 lg:gap-y-6 w-full ${
          loading ? "blur-md" : ""
        }`}
      >
        {role === "designManager" ? (
          <>
            <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
              <p className="w-[150px]">جستجو گرافیست:</p>
              <div className="flex sm:w-[calc(100%-150px)] bg-background shadow-regular rounded overflow-hidden">
                <input
                  readOnly={loading}
                  onChange={(e) => onChangeHandler(e, setGraphistSearchInput)}
                  type="text"
                  className="w-full bg-transparent p-1 outline-none border-none"
                />
                <button
                  className="bg-project-cyan text-white hover:animate-pulse  px-2 py-1  "
                  onClick={(e) => searchUser(e)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    dataSlot="icon"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                    />
                  </svg>
                </button>
              </div>
            </div>
            <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
              <p className="w-[150px]">گرافیست:</p>
              <select
                onChange={(e) => onChangeHandler(e, setGraphist)}
                readOnly={loading}
                type="text"
                className="w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
              >
                <option value="" selected disabled>
                  گرافیست را انتخاب کنید
                </option>
                {graphists.map((el) => (
                  <option value={el._id}>{el.name || el.phoneNumber}</option>
                ))}
              </select>
            </div>
          </>
        ) : (
          <></>
        )}

        <div className="flex gap-2 col-span-12  flex-col items-start sm:items-center sm:flex-row    ">
          <p className="w-[150px]">وضعیت :</p>
          <div className="flex w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded overflow-hidden">
            <select
              onChange={(e) => {
                onChangeHandler(e, setStatus);
              }}
              name=""
              id=""
              className="outline-none border-none w-full p-2 rounded bg-background"
            >
              <option value="rewrite" disabled selected>
                گزینه مورد نظر را انتخاب کنید
              </option>{" "}
              {role === "designManager" ? (
                <>
                  <option value="Design Manager Approved">تایید مدیر</option>
                  <option value="Images Assigned to be Designed">
                    ارسال برای گرافیست
                  </option>
                  <option value="Images Require Redesigning">
                    نیاز به بازطراحی
                  </option>

                  <option value="Images Designed">عکس ها طراحی شدند</option>
                  <option value="Images is Designing">
                    در حال طراحی عکس ها
                  </option>
                </>
              ) : (
                <>
                  <option value="Images Designed">عکس ها طراحی شدند</option>
                  <option value="Images is Designing">
                    در حال طراحی عکس ها
                  </option>
                </>
              )}
            </select>
            <button
              className="bg-project-green text-white hover:animate-pulse  px-2 py-1 "
              onClick={updateHandler}
            >
              ثبت
            </button>
          </div>
        </div>
        {role === "designManager" ? (
          <>
            {" "}
            <button
              className="bg-project-green text-white hover:animate-pulse  px-4 py-1 rounded "
              onClick={updateHandler}
            >
              ویرایش
            </button>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  );
};

export default DesignTeamEditDialog;
