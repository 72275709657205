import { useEffect } from "react";
import { Link } from "react-router-dom";

const Order = ({ order=[], setModal }) => {
    useEffect(()=>{
       order = Array
    })
  return (
    <div className="container absolute z-10 flex px-4 md:px-20 md:py-12  py-3">
      <div className="  w-full bg-white bg-opacity-60 rounded shadow-regular">
        <div className="flex w-full justify-between p-2">
          <p className="text-2xl text-primary font-bold opacity-50">سفارش شما</p>
          <button onClick={() => setModal(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>

        <div className={` w-full `}>
        <div className="hidden lg:grid grid-cols-6 items-center p-2   gap-2 bg-project-cyan bg-opacity-50  mb-4 ">
          <div className="col-span-2 text-center">
            <p className="text-sm lg:text-base">محصول</p>
          </div>
          <div className="col-span-2 text-center">
            <p>تعداد</p>
          </div>
          <div className="col-span-2 text-center">
            <p>هزینه</p>
          </div>

        </div>

        <>
          {order.length > 0 ? (
            <div className="flex flex-col w-full gap-4">
              {/* loop */}
              {order.map((el) => (
                <div className="grid grid-cols-6  items-center p-2   gap-4  justify-center">
                  <div className="col-span-6  lg:col-span-2 flex justify-center gap-4">
                    <p className="lg:hidden w-[150px]">محصول :</p>
                    <p className="px-2 py-1 bg-project-green bg-opacity-40 text-project-green lg:text-title lg:bg-transparent rounded w-full text-center">
                 <Link to={`/shop/${el.slug}`}>
                       {el.name}
                 </Link>
                    </p>
                  </div>
                  <div className="col-span-6  lg:col-span-2 flex justify-center gap-4 mb-4 lg:mb-0 ">
                    <p className="lg:hidden w-[150px]">تعداد :</p>
                    <p className="px-2 py-1 rounded w-full text-center">
                      {el.count}
                    </p>
                  </div>
                  <div className="col-span-6 sm:col-span-2 justify-start sm:justify-center   lg:col-span-2 text-center flex items-center gap-4 ">
                    <p className="lg:hidden w-[200px] text-right">
                    هزینه :
                    </p>
                    <p className="text-left w-full sm:text-center">
                      {el.amount}
                    </p>
                  </div>
                

                </div>
              ))}
            </div>
          ) : (
            <p className="w-full text-center">سفارشی یافت نشد</p>
          )}
        </>
      </div>
      </div>
      
    </div>
  );
};

export default Order;
