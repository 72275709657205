import { Link, Navigate } from "react-router-dom";
import { useContext } from "react";
import { ViewContext } from "../../context/ViewContext";
import { useSelector } from "react-redux";
import { useState } from "react";
import Cookies from "universal-cookie";
import { store } from "../../app/store";
import { setUser } from "../../features/user/userSlice";
import { useEffect } from "react";

const MobileMenu = () => {
  const { toggle } = useContext(ViewContext);
  const user = useSelector((state) => state.user);
  useEffect(() => {}, [user]);

  const [redirect, setRedirect] = useState(false);

  const cookies = new Cookies();
  const exitHandler = (e) => {
    e.preventDefault();
    cookies.remove("auth");
    store.dispatch(
      setUser({
        name: "",
        token: "",
        photo: "",
        id: "",
        role: "",
      })
    );
    setRedirect(true);
  };

  return (
    <div
      className={`md:hidden w-[80%] p-4  right-[10%] rounded bg-white  shadow-regular fixed mt-28 z-30   ${
        toggle ? " animate-fadeOut hidden" : " animate-fadeIn"
      } `}
    >
      {redirect ? <Navigate to="/auth/login" /> : ""}
      <ul className="flex flex-col gap-6 ">
        <li className=" flex justify-center items-center">
          <Link
            className=" flex  justify-start gap-4 w-[100px] items-center text-project-orange"
            to="#"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
            <span className="pt-1">داشبورد</span>
          </Link>
        </li>
        {user.role === "customer" ? (
          <li className=" flex justify-center items-center">
            <Link
              className=" flex  justify-start gap-4 w-[100px] items-center text-alternative"
              to="/projects"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                ></path>
              </svg>
              <span className="pt-1">پروژه ها</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        {user.role === "customer" ? (
          <li className=" flex justify-center items-center">
            <Link
              className=" flex  justify-start gap-4 w-[100px] items-center text-alternative"
              to="/contents"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                ></path>
              </svg>
              <span className="pt-1">محتوا ها</span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "contentManager" ? (
          <li className=" flex justify-center items-center">
            <Link
              className=" flex  justify-start gap-4 w-[100px] items-center text-alternative"
              to="/content-manager"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                ></path>
              </svg>
              <span className="pt-1">محتوا ها</span>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "ContentExpert" ? (
          <li className=" flex justify-center items-center">
            <Link
              className=" flex  justify-start gap-4 w-[100px] items-center text-alternative"
              to="/content-expert"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                ></path>
              </svg>
              <span className="pt-1">محتوا ها</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        {user.role === "customer" ? (
          <li className=" flex justify-center items-center">
            <Link
              className=" flex  justify-start gap-4 items-center  text-alternative"
              to="/shop"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                />
              </svg>

              <span className="pt-1 text-right">فروشگاه</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        {user.role === "customer" ? (
          <li className=" flex justify-center items-center">
            <Link
              className=" flex  justify-start gap-4 w-[100px] items-center text-alternative"
              to="/orders"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                />
              </svg>

              <span className="pt-1">مالی</span>
            </Link>
          </li>
        ) : (
          ""
        )}

        <li className=" flex justify-center items-center">
          <Link
            className=" flex  justify-start gap-4 w-[100px] items-center text-alternative"
            to="/profile"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
              ></path>
            </svg>
            <span className="pt-1">پروفایل</span>
          </Link>
        </li>
        <li className=" flex justify-center items-center">
          <button
            className=" flex  justify-start gap-4 w-[100px] items-center text-alternative"
            to="#"
            onClick={exitHandler}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
              ></path>
            </svg>
            <span className="pt-1">خروج</span>
          </button>
        </li>
      </ul>
    </div>
  );
};

export default MobileMenu;
