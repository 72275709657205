import { useState } from "react";
import { Navigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  serverForgotPassword,
  serverResendCode,
  serverResetPassword,
} from "../../services/authServices";
import Spinner from "../../spinner/Spinner";

const ForgotPassword = () => {
  // ------------------ variables -------------------------------

  const [loading, setLoading] = useState(false);
  const [view, setView] = useState(true);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [redirect, setRedirect] = useState(false);
  const [codeSendedAt, setTime] = useState(Date.now());

  // ------------------ functions -------------------------------

  // #handleresetPassword send code,password and handleResetCode to server and  reset the password
  const handleresetPassword = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (code === "" || password === "" || passwordConfirm === "") {
      setLoading(false);
      return toast.info("لطفا فیلد ها را به درستی پر کنید.");
    }
    try {
      const { data } = await serverResetPassword(
        code,
        password,
        passwordConfirm
      );
      setLoading(false);
      setRedirect(true);
      return toast.success("تغییر رمز عبور شما انجام شد.");
    } catch (error) {
      setLoading(false);
      return toast.error(error.response.data.message);
    }
  };

  // #handleResendCode send a new code to the number
  const handleResendCode = async (e) => {
    e.preventDefault();

    if (Date.now() - codeSendedAt < 120000)
      return toast.info("قبل از ارسال کد بعدی باید دو دقیقه صبر کنید.");

    try {
      const { data } = await serverForgotPassword(phone);
      setView(false);
      setLoading(false);

      return toast.success("کد احراز هویت برای شما ارسال شد");
    } catch (error) {
      setLoading(false);
      return toast.error(error.response.data.message);
    }
  };
  // #resetPasswordHandler send phone number to the server for sending code to the number
  const resetPasswordHandler = async (e) => {
    e.preventDefault();
    setLoading(true);

    if (phone === "") {
      setLoading(false);
      return toast.info("لطفا شماره تلفن خود را وارد کنید");
    }

    try {
      const { data } = await serverForgotPassword(phone);
      setView(false);
      setLoading(false);

      return toast.success("کد احراز هویت برای شما ارسال شد");
    } catch (error) {
      setLoading(false);
      return toast.error(error.response.data.message);
    }
  };

  // #onChangeHandler handel the input changes
  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };
  // ------------------ View -------------------------------

  return (
    <>
      {redirect ? <Navigate to="/auth/login" /> : ""}

      {view ? (
        <form
          action=""
          className="flex flex-col gap-12 h-[237px] justify-between"
        >
          <div className="flex justify-between items-center mb-8">
            <label htmlFor="phoneNumber" className="w-[max-content] bold">
              شماره تلفن:
            </label>
            <input
              onChange={(e) => onChangeHandler(e, setPhone)}
              value={phone}
              readOnly={loading}
              name="phoneNumber"
              type="text"
              placeholder="09110000000"
              className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none border-secondary p-2 w-[80%] rounded text-primary backdrop-blur-sm "
            />
          </div>
          <button
            className="p-2 bg-project-orange text-white text-center rounded flex items-center gap-2 justify-center"
            onClick={resetPasswordHandler}
          >
            {loading ? <Spinner /> : ""}
            <span>ارسال کد</span>
          </button>
        </form>
      ) : (
        <form action="" className="flex flex-col gap-4 ">
          <div className="flex justify-between items-center">
            <label htmlFor="code" className="w-[max-content] bold">
              کد ارسال شده
            </label>
            <input
              onChange={(e) => onChangeHandler(e, setCode)}
              readOnly={loading}
              name="code"
              value={code}
              type="text"
              placeholder="123456"
              className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none border-secondary p-2 w-[80%] rounded text-primary backdrop-blur-sm "
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <label htmlFor="password" className="w-[max-content] bold">
              رمز عبور
            </label>
            <input
              onChange={(e) => onChangeHandler(e, setPassword)}
              value={password}
              readOnly={loading}
              name="password"
              type="password"
              placeholder="***********"
              className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none border-project- p-2 w-[80%] rounded text-primary backdrop-blur-sm"
            />
          </div>
          <div className="flex justify-between items-center mb-4">
            <label htmlFor="passwordConfirm" className="w-[max-content] bold">
              تکرار رمز عبور
            </label>
            <input
              onChange={(e) => onChangeHandler(e, setPasswordConfirm)}
              readOnly={loading}
              value={passwordConfirm}
              name="passwordConfirm"
              type="password"
              placeholder="***********"
              className="bg-[#f1f6fa60] lg:bg-background border-2 outline-none border-project- p-2 w-[80%] rounded text-primary backdrop-blur-sm"
            />
          </div>
          <button
            className="p-2 bg-project-orange text-white text-center rounded flex items-center gap-2 justify-center"
            onClick={handleresetPassword}
          >
            {" "}
            {loading ? <Spinner /> : ""}
            <span>ویرایش رمز عبور</span>
          </button>
          <button className=" font-bold text-title" onClick={handleResendCode}>
            ارسال مجدد کد
          </button>
        </form>
      )}
    </>
  );
};

export default ForgotPassword;
