import ContentContainer from "./ContentsContainer";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { getCustomerContents, getCustomerWithoutProject } from "../../services/contentServices";

import Spinner from "../../spinner/Spinner";
import Pagination from "../includes/Pagination";
import { useSelector } from "react-redux";

const WithoutProjects = () => {
  const [contents, setContents] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(0);
  const [searchName, setSearchName] = useState("");
  const [searchStatus, setSearchStatus] = useState("");
  const [withoutProject, setWithoutProject] = useState(0);
  const user = useSelector((state) => state.user);
  
  let searchstr = `page=${page}&project=true`;

  useEffect(() => {
    fetchContent();
  }, [page, user]);

  const fetchContent = async () => {
    try {
      setLoading(true);
      const token = user.token;
      if (!token) return;
      const { data } = await getCustomerContents(token, searchstr);
      setResults(data.results);
      setWithoutProject(data.withoutProject);
      setResults(data.results);
      setContents(data.contents);
      console.log(data.contents);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  
  const SearchHandler = () => {
    if (page > 0) {
      searchstr = `page=${page}`;
    } else if (page < 0) {
      searchstr = searchstr + `page=1`;
    }
    if (searchName != "") searchstr = searchstr + `&name=${searchName}`;
    if (searchStatus != "") searchstr = searchstr + `&status=${searchStatus}`;

    fetchContent();
  };

  return (
    <div className="container px-4 md:px-20 md:py-12  py-32 flex flex-col items-center justify-center">
      {loading ? (
        <div className="absolute z-10 text-2xl">
          <Spinner />
        </div>
      ) : (
        <>
          <div className="flex w-full p-2 rounded bg-white mb-8 gap-2">
            {results > 0 ? (
              <div className="flex items-center gap-2">
                <p>تعداد کل محتواها: </p>
                <p className="px-[8px] py-[2px]  text-white  bg-project-cyan rounded-full">
                  {results}
                </p>
              </div>
            ) : (
              ""
            )}
            {withoutProject > 0 ? (
              <div className="flex items-center">
                <p>محتوا های بدون پروژه</p>
                <p className="px-[8px] py-[2px] mr-2 text-white  bg-project-red rounded-full">
                  {withoutProject}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          <ContentContainer loading={loading} contents={contents}   userRole={"customer"}/>
          <Pagination
            page={page}
            limit={10}
          
            results={results}
            setPage={setPage}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

export default WithoutProjects;
