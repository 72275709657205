import ContentContainer from "./ContentsContainer";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import {
 
  getDesignerContents,
 

} from "../../services/contentServices";

import Spinner from "../../spinner/Spinner";
import Pagination from "../includes/Pagination";
import { useSelector } from "react-redux";

const Designer = () => {
  const [contents, setContents] = useState([]);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(0);

  const [page, setPage] = useState(1);
  const user = useSelector((state) => state.user);
  const [forceUpdate, setForceUpdate] = useState(false);

  const [searchName, setSName] = useState("");
  const [searchStatus, setSStatus] = useState("");
  let searchstr = `page=${page}`;

  useEffect(() => {
    fetchContent();
  }, [page, user, forceUpdate]);

  const forceUpdateFunc = () => {
    setForceUpdate(!forceUpdate);
  };

  const fetchContent = async () => {
    try {
      setLoading(true);
      const token = user.token;
      if (!token) return;

      const { data } = await getDesignerContents(token, searchstr);

      setResults(data.results);
      setResults(data.results);
      setContents(data.contents);
      setLoading(false);
    } catch (e) {
      toast.error(e.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };
  const SearchHandler = () => {
    if (page > 0) {
      searchstr = `page=${page}`;
    } else if (page < 0) {
      searchstr = searchstr + `page=1`;
    }
    if (searchName != "") searchstr = searchstr + `&subject=${searchName}`;
    if (searchStatus != "") searchstr = searchstr + `&status=${searchStatus}`;

    fetchContent();
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  return (
    <div className="container px-4 md:px-20 md:py-12  py-32 flex flex-col items-center justify-center">
      {loading ? (
        <div className="absolute z-10 text-2xl">
          <Spinner />
        </div>
      ) : (
        ""
      )}

      <div className="grid grid-cols-7 w-full p-2 rounded bg-white mb-4 gap-2 ">
        <div className="col-span-7 sm:col-span-3  lg:col-span-3 flex items-center gap-2">
          <p>نام:</p>
          <input
            className="p-2 w-full lg:w-auto xl:w-[300px] text-primary outline-none border-none rounded bg-slate-100"
            placeholder="نام محتوا را وارد کنید"
            value={searchName}
            onChange={(e) => onChangeHandler(e, setSName)}
          />
        </div>
        <div className="col-span-7 sm:col-span-4 lg:col-span-3 flex items-center gap-2">
          {/* <p>وضعیت:</p>
          <select
            className="p-2 w-full lg:w-auto  xl:w-[300px] text-primary outline-none border-none rounded bg-slate-100"
            onChange={(e) => onChangeHandler(e, setSStatus)}
          >
<option value="item-1" selected disabled>
                وضعیت مورد نظر خود را انتخاب کنید.
              </option>
              <option value="Need rewriting">نیاز به بازنویسی</option>
              <option value="Written">نوشته شده</option>
              <option value="Editor approved">تایید ویراستار</option>
              <option value="Manager does not approved">
                عدم تایید مدیر محتوا
              </option>
              <option value="Manager approved">تایید مدیر محتوا</option>

              <option value="Customer Approved">تایید مشتری</option>
              <option value="Customer not Accepted">عدم تایید مشتری</option>
              <option value="Account Manager does not approved">
                عدم تایید مدیر محتوا
              </option>
          </select> */}
        </div>
        <button
          className="col-span-7 lg:col-span-1 px-[10px] py-[2px] bg-project-green text-white rounded"
          onClick={SearchHandler}
        >
          جستجو
        </button>
      </div>
      <ContentContainer
        userRole={"designer"}
        loading={loading}
        contents={contents}
        forceUpdate={forceUpdateFunc}
      />
      <Pagination
        page={page}
        limit={10}
        results={results}
        setPage={setPage}
        loading={loading}
      />
    </div>
  );
};

export default Designer;
