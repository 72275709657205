import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  editProject,
  uploadStructureFile,
} from "../../services/projectServices";
import Spinner from "../../spinner/Spinner";

const EditProject = ({
  project,
  setOpenEdit,
  id,
  forceUpdate,
  setForceUpdate,
}) => {
  const [name, setName] = useState("");
  const [site, setSite] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState({});

  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    setName(project.name);
    setSite(project.siteName);
    setDescription(project.description);
  }, []);

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };
  const fileChangeHandler = (e) => {
    setFile(e.target.files[0]);
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("siteName", site);
    formdata.append("description", description);

    try {
      const token = user.token;
      if (!token) return;

      const { data } = await editProject(token, formdata, id);
   
      toast.success("محتوا شما ویرایش شد");
      setLoading(false);
      setForceUpdate(!forceUpdate);
      setOpenEdit(false);
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  const fileUpload = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    setLoading(true);
    try {
      formdata.append("project", id);
      formdata.append("file", file);
      const token = user.token;
      if (!token) return;
      await uploadStructureFile(token, formdata);
      toast.success("فایل آپلود شد", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };
  return (
    <div className="absolute w-full z-10 -mr-3 -mt-3 flex justify-center items-center">
      {loading ? (
        <div className="absolute  md:w-auto z-10 flex flex-col gap-4">
          <Spinner />
        </div>
      ) : (
        ""
      )}
      <div
        className={`bg-white 2md:w-[90%] md:w-auto shadow-lg p-6 rounded border-2 border-project-green flex flex-col gap-8 ${
          loading ? "blur-sm" : ""
        }`}
      >
        <div className="flex justify-between items-center rounded-full">
          <p className=" font-bold text-2xl ">ویرایش پروژه</p>
          <button
            className=""
            onClick={(e) => {
              setOpenEdit(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-title"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <p className="w-[100px] text-title">نام پروژه:</p>
          <input
            type="text"
            className="w-full md:w-[400px] p-1 outline-none border-none bg-background rounded"
            value={name}
            onChange={(e) => onChangeHandler(e, setName)}
          />
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <p className="w-[100px] text-title">آدرس سایت</p>
          <input
            type="text"
            value={site}
            onChange={(e) => onChangeHandler(e, setSite)}
            className="w-full md:w-[400px] p-1 outline-none border-none bg-background rounded"
          />
        </div>

        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <p className="w-[100px] text-title">فایل ساختار:</p>
          <div className="flex w-full md:max-w-[400px] p-1 outline-none border-none bg-background rounded">
            <input type="file" className="w-full" onChange={fileChangeHandler} />
            <button
              onClick={fileUpload}
              className="m-[1px] bg-project-cyan bg-opacity-25 text-project-cyan px-2 py-1 rounded"
            >
              ارسال
            </button>
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <p className="w-[100px] text-title">توضیحات:</p>
          <textarea
            value={description}
            onChange={(e) => onChangeHandler(e, setDescription)}
            type="text"
            className="w-full md:w-[400px] p-1 outline-none border-none bg-background rounded"
          />
        </div>
        <button
          onClick={submitHandler}
          className="flex items-center gap-2 justify-center bg-project-orange   text-white p-2 rounded "
        >
          <p>ویرایش مشخصات</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default EditProject;
