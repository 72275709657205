import axios from "axios";

const SERVER_URL = "https://api.modirecontent.com/api/v1";
// const SERVER_URL = "http://127.0.0.1:5000/api/v1";

export const checkDiscount = (discount_code, prodList) => {
  const url = `${SERVER_URL}/discount/check-discount/${discount_code}`;
  return axios.post(url, {
    prodList,
  });
};
