import ContentExpertDialog from "./includes/ContentExpertDialog";
import ContentManagerDialog from "./includes/ContentManagerDialog";
import CustomerEditDialog from "./includes/CustomerEditDialog";
import AccountManagerEditDialog from "./includes/AccountManagerEditDialog";
import DesignTeamEditDialog from "./includes/DesignTeamEditDialog";

const EditDialog = ({
  setDialog,
  content = {},
  id,
  forceUpdate,
  projects = [],
  user,
}) => {
  return (
    <div className=" flex justify-center z-10 flex-col items-center  w-full  ">
      <div className=" bg-project-cyan bg-opacity-30 p-4 shadow-regular rounded  w-full flex flex-col items-center justify-center">
        <div className="flex justify-between w-full mb-8">
          <p>ویرایش مشخصات</p>
          <button
            onClick={() => {
              setDialog(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        {user === "customer" ? (
          <CustomerEditDialog
            content={content}
            projects={projects}
            id={id}
            forceUpdate={forceUpdate}
          />
        ) : (
          ""
        )}
        {user === "writer" || user === "editor" ? (
          <ContentExpertDialog
            role={user}
            content={content}
            id={id}
            forceUpdate={forceUpdate}
          />
        ) : (
          ""
        )}

        {user === "designer" || user === "designManager" ? (
          <DesignTeamEditDialog
            content={content}
            role={user}
            id={id}
            forceUpdate={forceUpdate}
          />
        ) : (
          ""
        )}

        {user === "contentManager" ? (
          <ContentManagerDialog
            content={content}
            id={id}
            forceUpdate={forceUpdate}
          />
        ) : (
          ""
        )}
        {user === "accountManager" ? (
          <AccountManagerEditDialog
            content={content}
            id={id}
            forceUpdate={forceUpdate}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EditDialog;
