import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  updateContentProject,
  updateHandler as contentUpdateHandler,
  statusHandler as updateCustomerStatus,
} from "../../../services/contentServices";

import Spinner from "../../../spinner/Spinner";

const CustomerEditDialog = ({
  content = {},
  id,
  forceUpdate,
  projects = [],
}) => {
  
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");

  const [status, setStatus] = useState("");

  const [newStatus, setNewStatus] = useState("");

  const [subject, setSubject] = useState("");
  const [project, setProject] = useState({});
  const [projectID, setProjectID] = useState("");

  useEffect(() => {
    
    setSubject(content.subject || "");
    setKeywords(content.keywords || "");
    setDescription(content.description || "");
  }, []);

  const trimHandler = (state) => {
    console.log("state:", state);

    const text = state;
    return text.trim() === "";
  };
  const updateHandler = async () => {
    try {
      const token = user.token;
      if (!token) return;
      console.log(keywords, description, subject);
      if (
        trimHandler(keywords) &&
        trimHandler(description) &&
        trimHandler(subject)
      )
        return toast.info("شما برای آپدیت حداقل یک فیلد را باید پر کنید.");

      const { data } = await contentUpdateHandler(
        token,
        { keywords, subject, description },
        id
      );
      toast.success("محتوا اپدیت شد");
      forceUpdate();
    } catch (error) {
      toast.warning(error.response.data.message);
      // toast.warning(error.message);
    }
  };
  const projectHandler = async (e) => {
    e.preventDefault();
    if (projectID === "") return toast.info("لطفا ابتدا یک پروژه انتخاب کنید");

    if (projectID === project.id)
      return toast.info("محتوا در پروژه انتخابی شما است و نیازی به آپدیت نیست");

    try {
      const token = user.token;
      if (!token) return;
      const { data } = await updateContentProject(token, {
        project: projectID,
        id,
      });
      setProject({
        id: data.content.project._id,
        name: data.content.project.name,
      });

      forceUpdate();
      toast.success("پروژه محتوا آپدیت شد");
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const statusHandler = async (e) => {
    e.preventDefault();
    try {
      if (newStatus === "")
        return toast.info("لطفا ابتدا یک وضعیت انتخاب کنید");
      const token = user.token;
      if (!token) return;

      const { data } = await updateCustomerStatus(token, {
        status: newStatus,
        id: id,
      });
      toast.success(data.message);
      setStatus(newStatus);
      forceUpdate();
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  return (
    <>
      {loading ? (
        <div className="absolute z-10 text-2xl">
          <Spinner />
        </div>
      ) : (
        ""
      )}
      <div
        className={`  grid grid-cols-12 gap-y-3 lg:gap-y-6 w-full ${
          loading ? "blur-md" : ""
        }`}
      >
        <div
          className={`flex gap-2 col-span-12 flex-col sm:flex-row items-start `}
        >
          <p className="w-[100px]"> شماره شاخص:</p>
          <input
            readOnly={true}
            value={id}
            type="text"
            className="w-full sm:w-[calc(100%-100px)] bg-background rounded p-1 outline-none border-none"
          />
        </div>
        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start ">
          <p className="w-[100px]">موضوع:</p>

          <input
            readOnly={loading}
            value={subject}
            onChange={(e) => onChangeHandler(e, setSubject)}
            type="text"
            className=" w-full sm:w-[calc(100%-100px)] bg-background rounded p-1 outline-none border-none"
          />
        </div>

        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start ">
          <p className="w-[100px]">پروژه:</p>
          <div className="flex w-full sm:w-[calc(100%-100px)] ">
            {projects.length > 0 ? (
              <select
                disabled={loading}
                onChange={(e) => {
                  onChangeHandler(e, setProjectID);
                }}
                type="text"
                className="w-full  bg-background rounded p-1 outline-none border-none"
              >
                <option value="item-1" selected disabled>
                  پروژه مورد نظر را انتخاب کنید
                </option>

                {projects.map((el) => (
                  <option value={`${el._id}`}>{el.name}</option>
                ))}
              </select>
            ) : (
              <input
                readOnly
                placeholder="شما پروژه ای تعریف نکرده اید"
                type="text"
                className=" w-full  bg-background rounded-r p-1 outline-none border-none"
              />
            )}

            <button
              disabled={projects.length < 1}
              onClick={projectHandler}
              className="bg-project-green px-2 rounded-l text-white"
            >
              ثبت
            </button>
          </div>
        </div>
        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start  ">
          <p className="w-[100px]">وضعیت:</p>
          <div className="flex w-full sm:w-[calc(100%-100px)] ">
            <select
              disabled={loading}
              onChange={(e) => {
                onChangeHandler(e, setNewStatus);
              }}
              type="text"
              className="w-full  bg-background rounded p-1 outline-none border-none"
            >
              <option value="item-1" selected disabled>
                وضعیت مورد نظر خود را انتخاب کنید.
              </option>

              {content.status.stage === "Pending Customer" &&
              content.status.text === "ordered" ? (
                <option value="submit">ارسال برای نگارش</option>
              ) : (
                <>
                  {" "}
                  <option value="Customer not Approved"> نیاز به بازنویسی</option>
                  <option value="Customer Approved">تایید محتوا</option>
                </>
              )}
            </select>

            <button
              onClick={statusHandler}
              className="bg-project-green px-2 rounded-l text-white"
            >
              ثبت
            </button>
          </div>
        </div>
        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start   ">
          <p className="w-[100px]">کلمات کلیدی:</p>
          <input
            readOnly={loading}
            value={keywords}
            onChange={(e) => onChangeHandler(e, setKeywords)}
            type="text"
            className="w-full sm:w-[calc(100%-100px)] bg-background rounded p-1 outline-none border-none"
          />
        </div>
        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start ">
          <p className="w-[100px]">توضیحات :</p>
          <textarea
            value={description}
            onChange={(e) => onChangeHandler(e, setDescription)}
            type="text"
            className="w-full sm:w-[calc(100%-100px)] bg-background rounded p-1 outline-none border-none"
          />
        </div>
        <div className="flex gap-4 items-center">
          <button
            onClick={updateHandler}
            className="bg-project-green text-white hover:animate-pulse  px-4 py-1 rounded "
          >
            ویرایش
          </button>
        </div>
      </div>
    </>
  );
};

export default CustomerEditDialog;
