import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  updateHandler as updateManagerContents,
  updateMany,
  statusHandler,
} from "../../../services/contentServices";
import { GetUsers } from "../../../services/userServices";
import Spinner from "../../../spinner/Spinner";

const ContentManagerDialog = ({ content, id, forceUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [loadingText, setLoadingText] = useState("لطفا منتظر بمانید");
  const user = useSelector((state) => state.user);

  //* Content Writer and Editor
  const [contentWriter, setContentWriter] = useState("");
  const [contentWriters, setContentWriters] = useState([]);
  const [contentWriterSearch, setContentWriterSearch] = useState("");

  const [contentEditor, setContentEditor] = useState("");
  const [contentEditors, setContentEditors] = useState([]);
  const [contentEditorSearch, setContentEditorSearch] = useState("");

  //* DueDate, ContentLink, finalwordcount
  const [finalWordCount, setFinalWordCount] = useState(undefined);
  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/;

  const [dueDate, setDueDate] = useState("");
  const [contentLink, setContentLink] = useState("");

  //*  Status
  const [status, setStatus] = useState("");
  const [statusInput, setStatusInput] = useState("");

  const [imageStatus, setImageStatus] = useState("");
  const [imageStatusInput, setImageStatusInput] = useState("");

  useEffect(() => {
    if (content.dueDate) {
      const match = content.dueDate.match(regex);
      console.log(match[1]);
      setDueDate(match ? match[1] : "2024-08-20T17:30:00");
    }

    setStatus(content.status);
    setContentLink(content.contentLink || "");
    setContentWriter(content.writer[0] ? content.writer[0]._id : "");
    setContentEditor(content.editor[0] ? content.editor[0]._id : "");
    setFinalWordCount(content.finalWordCount);
    setLoading(false);
  }, []);

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const searchUser = async (e, role) => {
    e.preventDefault();

    try {
      const token = user.token;
      if (!token) return;

      switch (role) {
        case "contentWriter":
          const { data: contentWriter } = await GetUsers(token, {
            name: contentWriterSearch,
            content: id,
            userRole: "contentTeam",
          });
          setContentWriters(contentWriter.user);
          break;
        case "contentEditor":
          const { data: contentEditor } = await GetUsers(token, {
            name: contentEditorSearch,
            content: id,
            userRole: "contentTeam",
          });
          setContentEditors(contentEditor.user);
          break;
      }
      //
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const updateHandler = async (e) => {
    e.preventDefault();
    const token = user.token;
    if (!token) return;
    try {
      setLoading(true);

      setLoadingText("در حال بروزرسانی محتوا");

      const { data } = await updateMany(
        token,

        {
          updateState: "contentManager",
          contentsID: [{ _id: id }],
          update: {
            status:statusInput,
            imageStatus:imageStatusInput,
            contentTeam: {
              writer: contentWriter,
              editor: contentEditor,
            },
            contentLink,
            dueDate: dueDate === "" ? "" : `${dueDate}Z`,
            finalWordCount,
          },
        }
      );
      
      forceUpdate();
      setLoading(false);
      toast.success("محتوا آپدیت شد");
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message);
    }
  };

  // const contentStatusHandler = async (e, role) => {
  //   e.preventDefault();
  //   try {
  //     const token = user.token;
  //     if (!token) return;
  //     switch (role) {
  //       case "content":
  //         if (statusInput === "")
  //           return toast.info("لطفا ابتدا یک وضعیت انتخاب کنید");
  //         const { data: contentStatusData } = await statusHandler(token, {
  //           status: statusInput,
  //           id: id,
  //         });
  //         toast.success(contentStatusData.message);
  //         break;
  //       case "image":
  //         if (imageStatusInput === "")
  //           return toast.info("لطفا ابتدا یک وضعیت انتخاب کنید");
  //         const { data: imageStatusData } = await statusHandler(token, {
  //           status: imageStatusInput,
  //           id: id,
  //         });
  //         toast.success(imageStatusData.message);
  //         break;
  //     }

  //     setStatus(statusInput);
  //   } catch (error) {
  //     toast.error(error.response.data.message);
  //   }
  // };

  return (
    <>
      {loading ? (
        <div className="absolute z-10 text-2xl">
          <Spinner />
          <p>{loadingText}</p>
        </div>
      ) : (
        ""
      )}
      <div
        className={`  grid grid-cols-12 gap-y-3 lg:gap-x-3 lg:gap-y-6 w-full ${
          loading ? "blur-md" : ""
        }`}
      >
        <div
          className={`flex gap-2 col-span-12 lg:col-span-4 flex-col sm:flex-row items-start sm:items-center `}
        >
          <p className="w-[150px]">کد محتوا:</p>
          <input
            value={id}
            readOnly
            type="text"
            className="w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
          />
        </div>
        <div
          className={`flex gap-2 col-span-12 lg:col-span-4 flex-col sm:flex-row items-start sm:items-center `}
        >
          <p className="w-[150px]">موضوع محتوا:</p>
          <input
            value={content.subject}
            readOnly
            type="text"
            className="w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
          />
        </div>
        <div className="flex gap-2 col-span-12 lg:col-span-4 flex-col sm:flex-row items-start sm:items-center ">
          <p className="w-[150px]">سایت:</p>

          <input
            value={content.projectDetails.siteName}
            readOnly={loading}
            type="text"
            className=" w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
          />
        </div>
        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start sm:items-center ">
          <p className="w-[150px]">توضیحات:</p>

          <textarea
            value={content.description}
            readOnly
            type="text"
            className=" w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
          />
        </div>

        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start sm:items-center  ">
          <p className="w-[150px]">کلمات کلیدی:</p>
          <input
            value={content.keywords}
            readOnly
            type="text"
            className=" w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
          />
        </div>

        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[150px]">جستحو نویسنده:</p>
          <div className="flex sm:w-[calc(100%-150px)] bg-background shadow-regular rounded overflow-hidden">
            <input
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setContentWriterSearch)}
              placeholder={
                content.writer && content.writer[0]
                  ? content.writer[0].name || content.writer[0].phoneNumber
                  : "نام یا شماره تلفن نویسنده را وارد کنید."
              }
              type="text"
              className="w-full bg-transparent p-1 outline-none border-none"
            />
            <button
              className="bg-project-cyan text-white hover:animate-pulse  px-2 py-1  "
              onClick={(e) => searchUser(e, "contentWriter")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                dataSlot="icon"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[150px]">نویسنده:</p>
          <select
            onChange={(e) => onChangeHandler(e, setContentWriter)}
            readOnly={loading}
            type="text"
            className="w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
          >
            <option value="" selected disabled>
              نویسنده را انتخاب کنید
            </option>
            {contentWriters.map((el) => (
              <option value={el._id}>{el.name || el.phoneNumber}</option>
            ))}
          </select>
        </div>
        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[150px]">جستجو ویراستار:</p>
          <div className="flex sm:w-[calc(100%-150px)] bg-background shadow-regular rounded overflow-hidden">
            <input
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setContentEditorSearch)}
              type="text"
              placeholder={
                content.editor && content.editor[0]
                  ? content.editor[0].name || content.editor[0].phoneNumber
                  : "شماره یا نام ویراستار را وارد کنید."
              }
              className="w-full bg-transparent p-1 outline-none border-none"
            />
            <button
              className="bg-project-cyan text-white hover:animate-pulse  px-2 py-1  "
              onClick={(e) => searchUser(e, "contentEditor")}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                dataSlot="icon"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[150px]">ویراستار:</p>
          <select
            onChange={(e) => onChangeHandler(e, setContentEditor)}
            readOnly={loading}
            type="text"
            className="w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
          >
            <option value="" selected disabled>
              ویراستار را انتخاب کنید
            </option>
            {contentEditors.map((el) => (
              <option value={el._id}>{el.name || el.phoneNumber}</option>
            ))}
          </select>
        </div>
        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col items-start sm:items-center sm:flex-row    ">
          <p className="w-[150px]">وضعیت محتوا:</p>
          <div className="flex w-full sm:w-[calc(100%-150px)]">
            <select
              onChange={(e) => {
                onChangeHandler(e, setStatusInput);
              }}
              name=""
              id=""
              className="outline-none border-none w-full p-2 rounded bg-gray-50"
            >
              <option value="" disabled selected>
                گزینه مورد نظر را انتخاب کنید
              </option>{" "}
              <option value="Assing to be Written">ارسال برای نگارش</option>
              <option value="Need rewriting">نیاز به بازنویسی</option>
              <option value="Written">نوشته شده</option>
              <option value="Editor approved">تایید ویراستار</option>
              <option value="Manager does not approved">
                عدم تایید مدیر محتوا
              </option>
              <option value="Manager approved">تاید مدیر محتوا</option>
            </select>
            {/* <button
              onClick={(e) => contentStatusHandler(e, "content")}
              className="bg-project-green px-2 rounded-l text-white"
            >
              ثبت
            </button> */}
          </div>
        </div>
        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col items-start sm:items-center sm:flex-row    ">
          <p className="w-[150px]">وضعیت عکس:</p>
          <div className="flex w-full sm:w-[calc(100%-150px)]">
            <select
              onChange={(e) => {
                onChangeHandler(e, setImageStatusInput);
              }}
              name=""
              id=""
              className="outline-none border-none w-full p-2 rounded bg-gray-50"
            >
              <option value="" disabled selected>
                گزینه مورد نظر را انتخاب کنید
              </option>{" "}
              <option value="Assing to be Designed">
                ارسال برای پروسه طراحی
              </option>
              <option value="Manager has not approved Images">
                عدم تایید عکس مدیر محتوا
              </option>
              <option value="Images require redesigning">
                عکس نیاز به طراحی مجدد دارد
              </option>
              <option value="Manager approved images">
                تایید عکس مدیر محتوا
              </option>
            </select>
            {/* <button
              onClick={(e) => contentStatusHandler(e, "image")}
              className="bg-project-green px-2 rounded-l text-white"
            >
              ثبت
            </button> */}
          </div>
        </div>
        <div className="flex gap-2 col-span-12 lg:col-span-6  flex-col items-start sm:items-center sm:flex-row    ">
          <p className="w-[150px]">مهلت انجام:</p>
          <input
            onChange={(e) => onChangeHandler(e, setDueDate)}
            readOnly={loading}
            type="datetime-local"
            value={dueDate}
            className="w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
          />
        </div>
        <div className="flex gap-2 col-span-12  lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[150px]">تعداد کلمات نهایی:</p>
          <input
            onChange={(e) => onChangeHandler(e, setFinalWordCount)}
            readOnly={loading}
            value={finalWordCount}
            type="number"
            min="0"
            className="w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
          />
        </div>
        <div className="flex gap-2 col-span-12   flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[150px]">لینک محتوا:</p>
          <input
            onChange={(e) => onChangeHandler(e, setContentLink)}
            readOnly={loading}
            value={contentLink}
            type="text"
            className="w-full sm:w-[calc(100%-150px)] bg-background shadow-regular rounded p-1 outline-none border-none"
          />
        </div>

        <div className="flex gap-4 items-center">
          <button
            className="bg-project-green text-white hover:animate-pulse  px-4 py-1 rounded "
            onClick={updateHandler}
          >
            ویرایش
          </button>
        </div>
      </div>
    </>
  );
};

export default ContentManagerDialog;
