import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getMonthList } from "../services/fileServices";

import Spinner from "../spinner/Spinner";

const FileMonthList = () => {
  const [month, setMonth] = useState([]);
  const [loading, setLoading] = useState(true);
  const { year } = useParams();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    getMonthHandler();
  }, []);
  const getMonthHandler = async () => {
    try {

      const token = user.token;
      if(!token) return toast.error("خطایی رخ داده است.")

      const { data } = await getMonthList(year,token);
     
      setMonth(data.month);
      setLoading(false);
    } catch (error) {
      // toast.error(error.response.data.message)
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-4">
            {month.map((el) => (
              <div className=" rounded py-5 px-10 text-center bg-violet-950 text-white">
                <Link to={`/files/${year}/${el}`}>{el}</Link>
              </div>
            ))}
          </div>
      
      )}
    </>
  );
};

export default FileMonthList;
