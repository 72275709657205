import { createSlice } from "@reduxjs/toolkit";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    name: "",
    token: "",
    photo: "",
    id: "",
    role: "",
    email: "",
    website: "",
  },
  reducers: {
    setUser: (state, action = {}) => {
      state.name = action.payload.name;
      state.photo = action.payload.photo;
      state.id = action.payload.id;
      state.role = action.payload.role;
      state.token = action.payload.token;
      state.email= action.payload.email;
      state.website= action.payload.website;
    },
  },
});
export const { setUser } = userSlice.actions;
export default userSlice.reducer;
