import { createSlice } from "@reduxjs/toolkit";
import Cookies from "universal-cookie";
const cookies = new Cookies();

export const cardSlice = createSlice({
  name: "card",
  initialState: {
    products: cookies.get("card") || [],
    discount: cookies.get("discount") || {},
    price: 0,
    amount: 0,
  },
  reducers: {
    setCard: (state, action = {}) => {
      state.products = action.payload;
      let sumPrices = 0;
      for (let i = 0; i < action.payload.length; i++) {
        sumPrices = sumPrices + action.payload[i].price;
      }

      state.price = sumPrices;
      if (state.discount.discount_code) {
        state.amount = amountCaculator(state);
      }
    },
    setDiscount: (state, action = {}) => {
      state.discount = action.payload;
      state.amount = amountCaculator(state);
    },
    caculateAmount: (state, action = {}) => {
      state.amount = amountCaculator(state);
    },
    caculatePrice: (state, action = {}) => {
      let sumPrices = 0;

      for (let i = 0; i < state.products.length; i++) {
        sumPrices = sumPrices + state.products[i].price;
      }

      state.price = sumPrices;
    },
  },
});
const amountCaculator = (state) => {
  let mustPayAmount = 0;

  if (!state.discount.discount_code) {
    return state.price;
  }

  if (
    state.discount.maxDiscount >=
    (state.price * state.discount.percent) / 100
  ) {
    mustPayAmount = state.price - (state.price * state.discount.percent) / 100;
    return mustPayAmount;
  } else if (
    state.discount.maxDiscount <
    (state.price * state.discount.percent) / 100
  ) {
    mustPayAmount = state.price - state.discount.maxDiscount;
    return mustPayAmount;
  }
};
export const {
  setCard,
  setDiscount: setStateDiscount,
  caculatePrice,
  caculateAmount,
} = cardSlice.actions;
export default cardSlice.reducer;
