import { useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  createProject,
  uploadStructureFile,
} from "../../services/projectServices";
import Spinner from "../../spinner/Spinner";

const CreateProject = ({ open, forceUpdate, seForceUpdate }) => {
  const [name, setName] = useState("");
  const [website, setWebsite] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState({});

  const [loadingMsg, setLoadingMSG] = useState("در حال ساخت پروژه");
  const [loading, setLoading] = useState(false);

  const user = useSelector((state) => state.user);
  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };
  const fileChangeHandler = (e) => {
  

    setFile(e.target.files[0]);
   
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const formdata = new FormData();

      formdata.append("name", name);
      formdata.append("siteName", website);
      formdata.append("description", description);
      const token = user.token;
      if (!token) return;

      const { data, status } = await createProject(token, formdata);

      if (file.name) {
        setLoadingMSG("درحال آپلود فایل");
        const fileData = new FormData();
        fileData.append("project", data.doc._id);
        fileData.append("file", file);
        
        await uploadStructureFile(token, fileData);
      }
      toast.success("پروژه شما ساخته شد");
      setName("");
      setDescription("");
      setWebsite("");
      setFile({});
      open(false);
      seForceUpdate(!forceUpdate);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
    //create project
    // if ok and if file exist
    // upload file
  };

  return (
    <div className="absolute w-full  backdrop-blur-md z-10 -mr-3 -mt-3 flex flex-col justify-center items-center">
      {loading ? (
        <div className="absolute  z-10 flex flex-col gap-4">
          <Spinner />
          <p className="font-bold bg-gradient-to-r to-project-cyan  from-title text-transparent bg-clip-text text-lg ">
            {loadingMsg}
          </p>
        </div>
      ) : (
        ""
      )}
      <div
        className={`bg-white 2md:w-[90%] md:w-auto shadow-lg p-6 rounded border-2 border-project-green flex flex-col gap-8 ${
          loading ? "blur-sm" : ""
        }`}
      >
        <div className="flex justify-between items-center rounded-full">
          <p className=" font-bold text-2xl text-project-cyan">ساخت پروژه</p>
          <button
            className=""
            onClick={(e) => {
              open(false);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 text-project-red"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
              />
            </svg>
          </button>
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <p className="w-[100px] text-title">نام پروژه:</p>
          <input
            value={name}
            onChange={(e) => onChangeHandler(e, setName)}
            type="text"
            className="w-full md:w-[400px]  p-1 outline-none border-none bg-background rounded"
          />
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <p className="w-[100px] text-title">آدرس سایت</p>
          <input
            value={website}
            onChange={(e) => onChangeHandler(e, setWebsite)}
            type="text"
            className="w-full md:w-[400px] p-1 outline-none border-none bg-background rounded"
          />
        </div>

        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <p className="w-[100px] text-title">فایل ساختار:</p>
          <div className="flex w-full md:max-w-[400px] p-1 outline-none border-none bg-background rounded">
            <input
              type="file"
              className=""
              readOnly={loading}
              onChange={(e) => fileChangeHandler(e)}
            />
          
          </div>
        </div>
        <div className="flex flex-col md:flex-row items-start md:items-center gap-2">
          <p className="w-[100px] text-title">توضیحات:</p>
          <textarea
            type="text"
            onChange={(e) => onChangeHandler(e, setDescription)}
            value={description}
            className="w-full md:w-[400px] p-1 outline-none border-none bg-background rounded"
          />
        </div>
        <button
          className="flex  items-start md:items-center gap-2 justify-center bg-project-green   text-white p-2 rounded "
          onClick={submitHandler}
        >
          <p className="mt-1">ساخت پروژه</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z"
            />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default CreateProject;
