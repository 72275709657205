import { useEffect } from "react";
import { useSelector } from "react-redux";
import Administrator from "./Administrator";
import ContentExpert from "./ContentExpert";
import ContentManager from "./ContentManager";
import Customer from "./Customer";

const Dashboard = () => {
  const user = useSelector((state) => state.user);
  useEffect(() => {}, [user]);

  return (
    <div className="container px-4 md:px-20 md:py-12  py-32 w-full flex flex-col  ">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-2">
        {user.role === "customer" ? <Customer /> : <></>}
        {user.role === "contentManager" ? <ContentManager /> : <></>}
        {user.role === "ContentExpert" ? <ContentExpert /> : <></>}
        {user.role === "administrator" ? <Administrator /> : <></>}
      </div>
    </div>
  );
};

export default Dashboard;
