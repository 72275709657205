import { useEffect } from "react";
import { verifyPayment } from "../../services/shopServices";
import { store } from "../../app/store";
import Cookies from "universal-cookie";

import { setCard, setStateDiscount } from "../../features/card/cardSlice";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import Spinner from "../../spinner/Spinner";
import { useSelector } from "react-redux";
const VerifyPayment = () => {
  const cookies = new Cookies();
  const user = useSelector((state) => state.user);
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState({});
  const [loading, setLoading] = useState(true);
  const [errMsg, setErrMsg] = useState("");
  const [authority, setAuthority] = useState(searchParams.get("Authority"));

  useEffect(() => {
    setAuthority(searchParams.get("Authority"));
    verifyHandler();
  }, []);

  // store.dispatch(setCard([],setDiscount({})))
  // cookies.remove("discount")
  // cookies.remove("card")

  const verifyHandler = async () => {
    try {
      const token = user.token;
      const data = (await verifyPayment(token, authority)) || {};

      if (data.status === 200) {
        setStatus({ status_code: 200, message: "پرداخت شما موفقیت آمیز بود" });
        store.dispatch(setCard([], setStateDiscount({})));
        cookies.remove("discount");
        cookies.remove("card");
        setStatus(200);
      }
      setLoading(false);
    } catch (error) {
      setStatus(400);

      setLoading(false);
      setErrMsg(error.response.data.message);
    }
  };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="container px-4 md:px-20 md:py-12  py-32">
          {status === 200 ? (
            <div className="flex items-center justify-center flex-col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-24 h-24 text-project-green"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12.75L11.25 15 15 9.75M21 12c0 1.268-.63 2.39-1.593 3.068a3.745 3.745 0 01-1.043 3.296 3.745 3.745 0 01-3.296 1.043A3.745 3.745 0 0112 21c-1.268 0-2.39-.63-3.068-1.593a3.746 3.746 0 01-3.296-1.043 3.745 3.745 0 01-1.043-3.296A3.745 3.745 0 013 12c0-1.268.63-2.39 1.593-3.068a3.745 3.745 0 011.043-3.296 3.746 3.746 0 013.296-1.043A3.746 3.746 0 0112 3c1.268 0 2.39.63 3.068 1.593a3.746 3.746 0 013.296 1.043 3.746 3.746 0 011.043 3.296A3.745 3.745 0 0121 12z"
                />
              </svg>
              <p>پرداخت شما تایید شد</p>
            </div>
          ) : (
            <div className="flex items-center justify-center flex-col">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-24 h-24 text-project-cyan"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                />
              </svg>

              <p>{errMsg}</p>
            </div>
          )}
        </div>
      )}
    </>
  );
};

export default VerifyPayment;
