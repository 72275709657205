import { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import EditDialog from "./EditDialog";

const ContentContainer = ({
  contents = [],
  loading = true,
  blur = false,
  forceUpdate,
  projects = [],
  userRole,
}) => {
  useEffect(() => {
    console.log("contents:", contents);
  }, []);
  const user = useSelector((data) => data.user);

  const [editBar, setEditBar] = useState(false);

  const [id, setId] = useState();

  useEffect(() => {});

  const statusConverter = (status) => {
    switch (status.stage) {
      case "Pending Customer":
        if (status.text === "ordered") {
          return "در انتظار ارسال برای نگارش کارفرما";
        }
        if (status.text === "The content is ready") {
          return "در انتظار بررسی کارفرما";
        }
        if (status.text === "The content is ready") {
          return "در انتظار بررسی کارفرما";
        }
        break;
      case "Customer Approved":
        return "تایید کارفرما";

      case "Pending Account Manager":
        return "در انتظار اکانت منیجر";

      case "Pending Writer":
        if (status.text === "Assing to be Written") {
          return "در انتظار نویسنده";
        }
        if (status.text === "The content is currently being written") {
          return "در حال نگارش";
        }
        if (status.text === "The content requires rewriting") {
          return "نیاز به بازنویسی";
        }
        break;

      case "Pending Editor":
        if (status.text === "The content has been written") {
          return "نیار به بررسی ویراستار";
        }
        if (status.text === "The manager does not approve") {
          return "عدم تایید مدیر محتوا";
        }
        if (status.text === "Customer has not approved the content") {
          return "عدم تایید مشتری";
        }
        break;

      case "Pending Content Manager":
        if (
          status.text === "Account Manager Assign Content to Content Manager"
        ) {
          return "اساین شده به مدیر محتوا";
        }
        if (status.text === "The content has been approved by the editor") {
          return "نیار به بررسی مدیر محتوا";
        }
        if (status.text === "The Account manager does not approve") {
          return "عدم تایید اکانت منیجر";
        }
      default:
        return "وضعیت تعیین نشده ";
    }
  };

  const imageStatusConverter = (status = { stage: "", text: "" }) => {
    switch (status.stage) {
      case "Pending Content Manager":
        if (
          status.text === "The images have been approved by the design manager"
        )
          return "نیاز به بررسی مدیر محتوا";
        break;

      case "Pending Design Manager":
        if (status.text === "Assing to Design Manager")
          return "اساین شده به مدیر مدیا";

        if (status.text === "Assing to be Designed")
          return "ارسال شده برای پروسه طراحی";

        if (status.text === "The manager has not approved the images")
          return "عدم تایید مدیر محتوا";
        if (
          status.text === "The images have been designed" ||
          status.text === "The images have been redesigned"
        )
          return "نیاز به بررسی مدیر طراحی";
        break;
      case "Pending Designer":
        if (status.text === "Images are currently being designed")
          return "عکس در حال طراحی است";

        if (status.text === "Images Assigned to be Designed")
          return "عکس ها برای طراحی به گرافیست اساین شدند";
      default:
        return "وضعیت تعیین نشده";
    }
  };

  const openEditBar = (id) => {
    const element = document.getElementById(`${id}`);
    element.className = "";
  };
  const closeEditBar = (id) => {
    const element = document.getElementById(`${id}`);
    element.className = "hidden";
  };

  return (
    <>
      <div className={`mt-10 w-full ${loading || blur ? "blur-sm" : ""}`}>
        <div className="hidden lg:grid grid-cols-7 items-center p-2 rounded  gap-2 bg-white mb-4 ">
          <div className="col-span-2 text-center">
            <p>عنوان</p>
          </div>
          <div className="col-span-2 text-center">
            <p className="text-sm lg:text-base">
              {user.role !== "customer" ? "نام سایت" : "نام محتوا"}
            </p>
          </div>

          <div className="col-span-1 text-center">
            <p>وضعیت عکس</p>
          </div>
          <div className="col-span-1 text-center">
            <p>وضعیت</p>
          </div>
          <div className="col-span-1 text-center">
            <p>عملیات‌ها</p>
          </div>
        </div>

        <>
          {contents.length > 0 ? (
            <div className="flex flex-col w-full gap-4">
              {/* loop */}
              {contents.map((el) => (
                <>
                  <div className="grid grid-cols-8 lg:grid-cols-7 items-center bg-white p-2 rounded  gap-4  justify-center">
                    <div className="col-span-8  lg:col-span-2 flex justify-center gap-4 mb-4 lg:mb-0 ">
                      <p className="lg:hidden w-[150px]">موضوع :</p>
                      <p className="px-2 py-1 bg-project-cyan bg-opacity-25 text-project-cyan rounded w-full text-center">
                        {el.subject ? el.subject : "عنوان محتوا تعیین نشده است"}
                      </p>
                    </div>

                    <div className="col-span-8  lg:col-span-2 flex justify-center gap-4">
                      <p className="lg:hidden w-[150px]">نام سایت</p>
                      <p className="px-2 py-1 bg-project-green bg-opacity-40 text-project-green lg:text-title lg:bg-transparent rounded w-full text-center">
                        {userRole === "accountManager" ||
                        userRole === "contentManager" ? (
                          <>
                            {el.projectDetails.siteName ||
                            el.projectDetails.name
                              ? el.projectDetails.siteName ||
                                el.projectDetails.name
                              : "نام سایت در پروژه درج نشده است."}
                          </>
                        ) : (
                          <>
                            {el.siteName
                              ? el.siteName
                              : "نام سایت تعیین نشده است"}
                          </>
                        )}
                      </p>
                    </div>

                    <div className="col-span-8 sm:col-span-4 justify-start sm:justify-center   lg:col-span-1 text-center flex items-center gap-4 ">
                      <p className="lg:hidden w-[200px] text-right">
                        {userRole != "customer" ? (
                          <>وضعیت عکس:</>
                        ) : (
                          <>تعداد کلمات:</>
                        )}
                      </p>
                      <p className="text-left w-full sm:text-center">
                        {userRole != "customer" ? (
                          <>{imageStatusConverter(el.imageStatus)}</>
                        ) : (
                          <>{el.finalWordCount}</>
                        )}
                      </p>
                    </div>
                    <div className="col-span-8 sm:col-span-4 justify-start sm:justify-center   lg:col-span-1 text-center flex items-center gap-4   ">
                      <p className="lg:hidden  w-[150px] text-right">وضعیت:</p>
                      <p className="text-left w-full sm:text-center">
                        {statusConverter(el.status)}
                      </p>
                    </div>
                    <div className="col-span-8 lg:col-span-1 flex items-center justify-center gap-1">
                      <Link
                        title="مشاهده محتوا"
                        to={`/contents/${el._id}`}
                        disabled={blur || loading ? true : false}
                        className=" p-1  flex justify-center text-project-green "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
                          />
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
                          />
                        </svg>
                      </Link>

                      {el.contentLink ? (
                        <Link
                          title="فولدر محتوا"
                          target={"_blank"}
                          to={el.contentLink}
                          disabled={blur || loading ? true : false}
                          className=" p-1  flex justify-center text-project-cyan "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M12 10.5v6m3-3H9m4.06-7.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                            />
                          </svg>
                        </Link>
                      ) : (
                        ""
                      )}
                      <button
                        onClick={() => openEditBar(el._id)}
                        title="ویرایش محتوا"
                        disabled={blur || loading ? true : false}
                        className=" p-1  flex justify-center text-project-orange "
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          viewBox="0 0 24 24"
                          strokeWidth={1.5}
                          stroke="currentColor"
                          className="w-6 h-6"
                        >
                          <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>

                  <div className="hidden" id={el._id}>
                    <EditDialog
                      setDialog={() => closeEditBar(el._id)}
                      content={el}
                      projects={projects}
                      id={el._id}
                      forceUpdate={forceUpdate}
                      user={userRole}
                    />
                  </div>
                </>
              ))}
            </div>
          ) : (
            <p className="w-full text-center">محتوایی یافت نشد</p>
          )}
        </>
      </div>
    </>
  );
};

export default ContentContainer;
