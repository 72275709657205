import axios from "axios";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const SERVER_URL = "https://api.modirecontent.com/api/v1/file";
// const SERVER_URL = "http://127.0.0.1:5000/api/v1/file";


export const getYearList = (token) => {
    const url = `${SERVER_URL}`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${
    token
    }`;
  
    return axios.get(url);
  };

  export const getMonthList = (year,token) => {
    const url = `${SERVER_URL}/${year}`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${
    token
    }`;
  
    return axios.get(url);
  };
  export const getFileList = (year,month,token) => {
    const url = `${SERVER_URL}/${year}/${month}`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${
    token
    }`;
  
    return axios.get(url);
  };


  export const imageUpload = (formdata,token)=>{

    const url = `${SERVER_URL}/`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${
    token
    }`;
    return axios.post(url,formdata);
  }
  // fileAddress

  
  export const deleteImage = (fileName,token)=>{

    const formdata = new FormData()
    formdata.append("fileName",fileName)
    const url = `${SERVER_URL}/delete`;
    axios.defaults.headers.common["Authorization"] = `Bearer ${
    token
    }`;
    return axios.post(url,formdata);
  }