import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getProduct } from "../../services/shopServices";
import Cookies from "universal-cookie";
import { store } from "../../app/store";
import { setCard } from "../../features/card/cardSlice";
import Spinner from "../../spinner/Spinner";
import { useSelector } from "react-redux";

const Product = () => {
  // * ------------------------------ variables ------------------------------
  const user = useSelector((state) => state.user);
  const card = useSelector((state) => state.card);
  const uuid = require("uuid");
  const cookies = new Cookies();
  const [product, setProduct] = useState({});
  const [wordCount, setWordCount] = useState(1000);
  const [contentCount, setCountentCount] = useState(1);
  const [loading, setLoading] = useState(true);

  const [price, setPrice] = useState(0);

  const { slug } = useParams();

  // * ------------------------------ life cycle -----------------------------
  useEffect(() => {
    getProductHandler();
  }, [user]);

  // * ------------------------------ functions ------------------------------

  const getProductHandler = async () => {
    try {
      if (user.token === "") return;
      const token = user.token;
      const { data } = await getProduct(token, slug);

      setProduct(data.doc);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (error.response.status == 401) {
        return toast.error("لطفا برای دسترسی مجددا وارد اکانت شوید.");
      }
    }
  };

  const caculator = (e) => {
    setPrice(wordCount * contentCount * product.price);
  };

  const submitHandler = (e) => {
    caculator(e);
    let products = [];
    if (wordCount <= 700 || contentCount <= 0)
      return toast.error(
        "حداقل تعداد سفارش یک عدد و حداقل تعداد کلمات باید 700 کارکتر باشد"
      );
    if (card.products.length > 0) {
      products = [...card.products];
    }

    products.push({
      wordCount: wordCount,
      count: contentCount,
      product_id: product._id,
      price: wordCount * contentCount * product.price,

      img: product.imageCover || "/img/post-img.webp",
      name: product.name,
      slug: product.slug,
      id: uuid.v1(),
    });

    cookies.set("card", products, {
      path: "/",
      expires: new Date(Date.now() + 2592000),
    });

    store.dispatch(setCard(products));

    toast.success("محصول به سبد خرید اضافه شد");
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  //     if (e.target.value < 0 || e.target.value == 0)
  // toast.error("تعداد کلمات و تعداد محتوا نمی تواند منفی باشد");
  // };

  return (
    <div className="px-4 md:px-20 md:py-12  py-32 container">
      {loading ? (
        <div className="h-full w-full absolute z-30 backdrop-blur-sm flex items-center justify-center gap-4 text-2xl ">
          <div className="bg-white p-2 rounded shadow-regular bg-opacity-70">
            <p>درحال دریافت اطلاعات</p>
            <Spinner />
          </div>
        </div>
      ) : (
        ""
      )}
      <div className="flex flex-col gap-2 mb-4">
        <ul className="flex gap-1 text-primary">
          <li>
            <Link to="/">خانه</Link>
          </li>
          /
          <li>
            <Link to="/shop">فروشگاه</Link>
          </li>
          /
          <li>
            <Link to={`/shop/${product.slug}`}> {product.name}</Link>
          </li>
        </ul>
        <h1 className="text-right text-3xl bg-gradient-to-r to-title  from-project-cyan text-transparent bg-clip-text">
          {product.name}
        </h1>
      </div>
      <div className="grid grid-cols-1 md:grid-cols-12 gap-4 ">
        <div className="col-span-1  md:col-span-7 lg:col-span-8 leading-8">
          {/* tite / breadcrumb */}

          <img
            src={
              product.imageCover != ""
                ? `[https://api.modirecontent.com/img/${product.imageCover}]`
                : "/img/post-img.webp"
            }
            alt=""
            className="w-full rounded"
          />
          {product.mainText}
        </div>
        <div className="col-span-1 md:col-span-5 lg:col-span-4">
          <div className="flex  bg-white flex-col gap-4 p-4">
            <h2 className="bg-gradient-to-r to-title  from-project-cyan font-bold  text-transparent bg-clip-text text-xl  lg:text-2xl text-center">
              سفارش تولید محتوا
            </h2>
            <hr className="opacity-30" />
            <div className=" flex gap-4 flex-col lg:pt-5">
              <div className="flex flex-col lg:flex-row items-center">
                <p className="w-[150px] text-center lg:text-right ">تعرفه:</p>
                <p className="w-full bg-project-green text-white font-bold rounded p-1 text-center">
                  کلمه ای <span className="font-bold">{product.price}</span>{" "}
                  تومان
                </p>
              </div>
              <div className="flex flex-col lg:flex-row items-center">
                <p className="w-[150px]   text-center lg:text-right  ">
                  وضعیت:
                </p>
                <p className={`w-full ${product.status === "orderable"?"bg-project-cyan":"bg-project-red"}  text-white font-bold rounded p-1 text-center`}>
                  {product.status === "orderable"
                    ? "قابل سفارش"
                    : "اتمام ظرفیت"}
                </p>
              </div>
              <hr className="opacity-50" />
              <div className="flex flex-col lg:flex-row items-center">
                <label
                  htmlFor=""
                  className="w-[150px] text-center lg:text-right"
                >
                  تعداد کلمات:
                </label>
                <input
                  onChange={(e) => {
                    onChangeHandler(e, setWordCount);
                  }}
                  type="Number"
                  className="w-full bg-background px-1 text-center p-1"
                  placeholder="1000"
                />
              </div>
              <div className="flex flex-col lg:flex-row items-center mb-4">
                <label
                  htmlFor=""
                  className="w-[150px]  text-center lg:text-right"
                >
                  تعداد محتوا:
                </label>
                <input
                  onChange={(e) => {
                    onChangeHandler(e, setCountentCount);
                  }}
                  type="Number"
                  className="w-full bg-background px-1 text-center p-1"
                  placeholder="1"
                />
              </div>
            </div>
            <hr className="opacity-50" />

            <div className="flex justify-center items-end gap-1">
              <p className="lg:text-3xl text-sm ">{price.toLocaleString()}</p>
              <p className="lg:text-3xl text-sm ">تومان</p>
            </div>
            <button
              className="bg-project-green text-white rounded leading-9"
              onClick={caculator}
            >
              محاسبه قیمت
            </button>
            {product.status == "orderable" ? (
              <>
                <button
                  className="bg-project-orange text-white rounded leading-9"
                  onClick={submitHandler}
                >
                  ثبت در سبد خرید
                </button>
              </>
            ) : (
              <button
                className="bg-project-red text-white rounded leading-9"
                disabled
              >
                این محصول تکمیل ظرفیت می باشد.
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Product;
