import { createSlice } from "@reduxjs/toolkit";

export const contentSlice = createSlice({
  name: "contents",
  initialState: {
    contentEditor: [],
    contentWriter: [],
    contentManager: [],
    contents: [],
    designer: [],
    designManager: [],
  },
  reducers: {
    setContents: (state, action = {}) => {
      state.contentEditor = action.payload.contentEditor;
      state.contentWriter = action.payload.contentWriter;
      state.contentManager = action.payload.contentManager;
      state.contents = action.payload.contents;
      state.designer = action.payload.designer;
      state.designManager= action.payload.designManager;
    },
  },
});
export const { setContents } = contentSlice.actions;
export default contentSlice.reducer;
