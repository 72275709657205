import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getMyProjects } from "../../services/projectServices";
import Spinner from "../../spinner/Spinner";
import Pagination from "../includes/Pagination";
import CreateProject from "./CreateProject";

const Projects = () => {
  const [openEdit, setOpenEdit] = useState(false);
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [forceUpdate, seForceUpdate] = useState(false);
  const [results, setResults] = useState(0);

  const [page, setPage] = useState(1);

  const user = useSelector((state) => state.user);
  const [searchName, setSName] = useState("");
  const [siteName, setSiteName] = useState("");

  let searchstr = `page=${page}`;

  const SearchHandler = () => {
    if (page > 0) {
      searchstr = `page=${page}`;
    } else if (page < 0) {
      searchstr = searchstr + `page=1`;
    }
    if (searchName != "") searchstr = searchstr + `&name=${searchName}`;
    if (siteName != "") searchstr = searchstr + `&siteName=${siteName}`;

    getProjects();
  };

  useEffect(() => {
    getProjects();
  }, [user, forceUpdate, page]);

  const getProjects = async () => {
    try {
      const token = user.token;
      if (!token) return;
      setLoading(true);
      const data = await getMyProjects(token, searchstr);
      // setResults(data.results)
      setProjects(data.data.projects);
      setLoading(false);
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message);
    }
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  return (
    <div className="container px-4 md:px-20 md:py-12  py-32 w-full flex flex-col  items-center">
      {openEdit ? (
        <CreateProject
          open={setOpenEdit}
          forceUpdate={forceUpdate}
          seForceUpdate={seForceUpdate}
        />
      ) : (
        ""
      )}
      {loading ? (
        <div className="absolute z-10">
          <Spinner />
        </div>
      ) : (
        <>
          <div className={`w-full ${openEdit || loading ? "blur-md" : ""}`}>
            <button
              className="font-bold bg-gradient-to-r to-title  from-project-cyan text-transparent bg-clip-text text-lg mb-4"
              onClick={() => setOpenEdit(true)}
              disabled={openEdit}
            >
              ساخت پروژه جدید
            </button>
            <hr className="opacity-50" />
          </div>
          <div className="grid grid-cols-7 w-full p-2 rounded bg-white mb-4 gap-2 ">
            <div className="col-span-7 sm:col-span-3  lg:col-span-3 flex items-center gap-2">
              <p>نام:</p>
              <input
                className="p-2 w-full lg:w-auto xl:w-[300px] text-primary outline-none border-none rounded bg-slate-100"
                placeholder="نام محتوا را وارد کنید"
                value={searchName}
                onChange={(e) => onChangeHandler(e, setSName)}
              />
            </div>
            <div className="col-span-7 sm:col-span-4 lg:col-span-3 flex items-center gap-2">
              <p>آدرس سایت:</p>
              <input
                className="p-2 w-full lg:w-auto xl:w-[300px] text-primary outline-none border-none rounded bg-slate-100"
                placeholder="نام محتوا را وارد کنید"
                value={siteName}
                onChange={(e) => onChangeHandler(e, setSiteName)}
              />
            </div>
            <button
              className="col-span-7 lg:col-span-1 px-[10px] py-[2px] bg-project-green text-white rounded"
              onClick={SearchHandler}
              disabled={openEdit}
             
            >
              جستجو
            </button>
          </div>
          <div
            className={`grid grid-cols-2 md:grid-cols-4 lg:grid-cols-8 gap-8 mt-8 w-full ${
              openEdit ? "blur-md" : ""
            }`}
          >
            {projects.map((el) => (
              <Link
                to={`/projects/${el._id}`}
                className="col-span-2 bg-white shadow-regular  py-4 px-2 rounded flex gap-4 items-center justify-center flex-wrap"
              >
                <div className="lg:w-full xl:w-auto flex justify-center">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="h-8 w-8 bg-project-red text-project-red bg-opacity-20 p-1 rounded-full"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M9 17.25v1.007a3 3 0 01-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0115 18.257V17.25m6-12V15a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 15V5.25m18 0A2.25 2.25 0 0018.75 3H5.25A2.25 2.25 0 003 5.25m18 0V12a2.25 2.25 0 01-2.25 2.25H5.25A2.25 2.25 0 013 12V5.25"
                    />
                  </svg>
                </div>
                <p className="font-bold bg-gradient-to-r to-title  from-project-cyan text-transparent bg-clip-text text-lg mt-[1px] ">
                  {el.name}
                </p>
              </Link>
            ))}
          </div>
          <Pagination
            page={page}
            limit={10}
            results={results}
            setPage={setPage}
            loading={loading}
          />
        </>
      )}
    </div>
  );
};

export default Projects;
