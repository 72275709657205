import { useEffect } from "react";

const Pagination = ({ page, setPage, results, limit, loading,blur=false }) => {
  useEffect(()=>{

  },[])
  return (
    <div className={`mt-10 w-full ${loading || blur? "blur-sm" : ""}`}>
      <ul className="flex items-center gap-4 justify-center ">
        { results/limit > 1 && page !==1? (
          <>
            <li>
              <button disabled={blur || loading? true:false}
                onClick={() => {
                  setPage(1);
                }}
                className=" rounded text-white pt-1 px-3 bg-primary"
              >
                1
              </button>
            </li>
            ...
          </>
        ) : (
          ""
        )}
        {page - 1 > 1 ? (
          <li>
            <button disabled={blur || loading? true:false}
              onClick={() => {
                setPage(page - 1);
              }}
              className=" rounded text-white pt-1 px-3 bg-primary"
            >
              {page - 1}
            </button>
          </li>
        ) : (
          ""
        )}
       {
       ( results/limit) > 1?(
          <li>
          <button disabled={blur || loading? true:false} className=" rounded text-white pt-1 px-3 bg-project-orange cursor-default">
           {page}
          </button>
        </li>
        ):""
       }
        {page + 1 < (results / limit) ? (
          <li>
            <button disabled={blur || loading? true:false}
              onClick={() => {
                setPage(page + 1);
              }}
              className=" rounded text-white pt-1 px-3 bg-primary"
            >
              {page + 1}
            </button>
          </li>
        ) : (
          ""
        )}

        { results/limit > 2 && page !== (results/limit) ? (
          <>
            ...
            <li>
              <button disabled={blur || loading? true:false}
                onClick={() => {
                  setPage(Math.ceil(results / limit));
                }}
                className=" rounded text-white pt-1 px-3 bg-primary"
              >
                {Math.ceil(results / limit)}
              </button>
            </li>
          </>
        ) : (
          ""
        )}
      </ul>
    </div>
  );
};

export default Pagination;
