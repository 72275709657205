import { configureStore } from "@reduxjs/toolkit";

import userReducer from "../features/user/userSlice";
import cardReducer from "../features/card/cardSlice";
import contentReducer from "../features/contents/contentSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    card: cardReducer,
    content: contentReducer,
  }
});
