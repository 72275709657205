import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getProducts } from "../../services/shopServices";
import Spinner from "../../spinner/Spinner";

const Shop = () => {
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const [page, setPage] = useState(1);
  const [category, setCategory] = useState("");
  const [name, setName] = useState("");
  const [refresh, setRefresh] = useState(false);
  const [results, setResults] = useState(10);
  const [limit, setLimit] = useState(10);
  const [products, setProducts] = useState([]);

  useEffect(() => {
    fetchProductHandler();
  }, [refresh, page, user]);

  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };
  const fetchProductHandler = async () => {
    try {
      setLoading(true);
      const token = user.token;
      const filter = `${name !== "" ? `name=${name}` : ""}${
        category !== "" ? `&category=${category}` : ""
      }${page != 1 && page > 1 ? `&page=${page}` : `&page=1`}`;
      if (user.token === "") return;
      const { data } = await getProducts(token, filter);
      setProducts(data.products);
   
      setLoading(false);
    } catch (error) {
    
      setLoading(false);
      if (error.response.status == 401) {
        return toast.error("لطفا برای دسترسی مجددا وارد اکانت شوید.");
      }
    }
  };

  return (
    <div className="w-full px-4 md:px-20 md:py-12  py-32 container relative">
      {loading ? (
        <div className="h-full w-full absolute z-30 backdrop-blur-sm flex items-center justify-center gap-4 text-2xl">
          <p>درحال دریافت اطلاعات</p>
          <Spinner />
        </div>
      ) : (
        ""
      )}
      <div className=" sm:grid sm:grid-cols-12 sm:gap-8">
        <div className="mb-8 col-span-12 lg:col-span-8 ">
          {/* tite / breadcrumb */}
          <div className="flex flex-col gap-2 mb-4">
            <ul className="flex gap-1 text-primary">
              <li>
                <Link to="/">خانه</Link>
              </li>
              /
              <li>
                <Link to="/shop">فروشگاه</Link>
              </li>
            </ul>
            <h1 className="text-right text-3xl text-title">
              <span>فروشگاه </span>
              <span className="text-project-orange">مدیر محتوا </span>
            </h1>
          </div>
          <div className="grid  grid-cols-1  gap-4 mb-8">
            {/* product */}
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
              {products.map((el) => (
                <div className="col-span-1 bg-white shadow-regular p-2 rounded flex flex-col gap-4">
                  <img
                    src={
                      el.imageCover
                        ? `https://api.modirecontent.com/img/${el.imageCover}`
                        : "/img/post-img.webp"
                    }
                    alt=""
                    className="w-full rounded"
                  />
                  <p className="text-lg text-title">{el.name}</p>
                  
                  <Link
                    to={`/shop/${el.slug}`}
                    className="bg-project-green p-1 rounded text-sm text-white"
                  >
                    مشاهده و سفارش محصول
                  </Link>
                </div>
              ))}
            </div>
          </div>
          {/* pagination */}
          <div className="">
            <ul className="flex items-center gap-4 justify-center ">
              <li>
                <button
                  onClick={() => {
                    setPage(1);
                  }}
                  className=" rounded text-white pt-1 px-3 bg-primary"
                >
                  1
                </button>
              </li>
              ...
              {page - 1 > 1 ? (
                <li>
                  <button
                    onClick={() => {
                      setPage(page - 1);
                    }}
                    className=" rounded text-white pt-1 px-3 bg-primary"
                  >
                    {page - 1}
                  </button>
                </li>
              ) : (
                ""
              )}
              <li>
                <button className=" rounded text-white pt-1 px-3 bg-project-orange">
                  {page}
                </button>
              </li>
              {page + 1 < results / limit ? (
                <li>
                  <button
                    onClick={() => {
                      setPage(page + 1);
                    }}
                    className=" rounded text-white pt-1 px-3 bg-primary"
                  >
                    {page + 1}
                  </button>
                </li>
              ) : (
                ""
              )}
              ...
              <li>
                <button
                  onClick={() => {
                    setPage(results / limit);
                  }}
                  className=" rounded text-white pt-1 px-3 bg-primary"
                >
                  {results / limit}
                </button>
              </li>
            </ul>
          </div>
        </div>
        {/* side bar */}
        <div className="col-span-12 lg:col-span-4 flex flex-col gap-4">
          <div className=" p-2 flex items-center gap-2 bg-white rounded">
            <p className="w-[250px] text-sm ">مرتب سازی بر اساس: </p>
            <select name="" id="" className="bg-background py-1 px-2 w-full">
              <option value="content" disabled selected>
                محتوا ها
              </option>
            </select>
          </div>
          <div className=" p-2 bg-white rounded">
            <form
              action=""
              className="bg-background rounded overflow-hidden  flex w-full"
            >
              <input
                type="text"
                value={name}
                onChange={(e) => onChangeHandler(e, setName)}
                placeholder="جستجو محصول"
                className="w-full border-none outline-none p-1 bg-background "
              />
              <button
                className="p-1"
                onClick={(e) => {
                  e.preventDefault();
                  setRefresh(!refresh);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1.5}
                  stroke="currentColor"
                  className="w-6 h-6"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
                  />
                </svg>
              </button>
            </form>
          </div>
          {/* <div className="bg-white ronded p-2">
            <img src="/img/download.jpg" alt="" className="w-full rounded" />
          </div>
          <div className="bg-white ronded p-2">
            <img src="/img/download.jpg" alt="" className="w-full rounded" />
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default Shop;
