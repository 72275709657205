import { useState } from "react";
import { useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { deleteImage, getFileList } from "../services/fileServices";

import Spinner from "../spinner/Spinner";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { useSelector } from "react-redux";

const Files = () => {
  const [loading, setLoading] = useState(true);
  const [files, setFiles] = useState([]);
  const [copy, setCopy] = useState(false);
  const { year, month } = useParams();
  const [forceUpdate, setForceUpdate] = useState(false);
  const user = useSelector((state) => state.user);

  useEffect(() => {
    getMonthHandler();
  }, [forceUpdate]);

  const getMonthHandler = async () => {
    try {
        const token = user.token;
        if(!token) return toast.error("خطایی رخ داده است.")
      const { data } = await getFileList(year, month,token);
      console.log(data);
      setFiles(data.files);
      setLoading(false);
    } catch (error) {
      // toast.error(error.response.data.message)
      toast.error(error.response.data.message);
    }
  };

  const copyHandler = async () => {
    setCopy(true);
    toast.success("درس به کلیپ برد شما منتقل شد");
  };

  const deleteHandler = async (fileAddress) => {
    try {
      console.log(fileAddress);
      const token = user.token;
      if(!token) return toast.error("خطایی رخ داده است.")
      await deleteImage(fileAddress,token);
      setForceUpdate(!forceUpdate);
      toast.success("عکس مورد نظر حذف شد");
    } catch (error) {
      toast.error(error.response.data.message);
      // toast.error(error.response.data.message);
    }
  };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-4 mt-4">
          {files.map((el) => (
            <div className=" rounded text-primary bg-white shadow-regular relative overflow-hidden">
              <img
                className="w-full aspect-video"
                src={`https://modirecontent.com/uploads/${year}/${month}/${el}`}
                alt=""
              />
              <div className="flex gap-1 py-4 justify-center w-full items-center">
                <CopyToClipboard
                  text={`https://modirecontent.com/uploads/${year}/${month}/${el}`}
                  onCopy={copyHandler}
                >
                  <button className="bg-primary text-white rounded p-1  ">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                      strokeWidth={1.5}
                      stroke="currentColor"
                      className="w-6 h-6"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M8.25 7.5V6.108c0-1.135.845-2.098 1.976-2.192.373-.03.748-.057 1.123-.08M15.75 18H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08M15.75 18.75v-1.875a3.375 3.375 0 00-3.375-3.375h-1.5a1.125 1.125 0 01-1.125-1.125v-1.5A3.375 3.375 0 006.375 7.5H5.25m11.9-3.664A2.251 2.251 0 0015 2.25h-1.5a2.251 2.251 0 00-2.15 1.586m5.8 0c.065.21.1.433.1.664v.75h-6V4.5c0-.231.035-.454.1-.664M6.75 7.5H4.875c-.621 0-1.125.504-1.125 1.125v12c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V16.5a9 9 0 00-9-9z"
                      />
                    </svg>
                  </button>
                </CopyToClipboard>

                <button
                  className="bg-project-red text-white rounded p-1 "
                  onClick={() => deleteHandler(`${year}/${month}/${el}`)}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                    />
                  </svg>
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default Files;
