import { useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { getYearList } from "../services/fileServices";
import Spinner from "../spinner/Spinner";

const FileYearList = () => {
  const [years, setyears] = useState([]);
  const [loading, setLoading] = useState(true);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    getYearHandler();
  }, []);

  const getYearHandler = async () => {
    try {
      const token = user.token;
      if (!token) return toast.error("خطایی رخ داده است.");

      const { data } = await getYearList(token);
      console.log(data);
      setyears(data.years);
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  return (
    <>
      {loading ? (
        <Spinner />
      ) : (
        <div className="w-full grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-8 gap-4">
          {years.map((el) => (
            <Link to={`/files/${el}`}>
              {" "}
              <div className=" rounded py-5 px-10 text-center bg-violet-950 text-white">
                {el}
              </div>
            </Link>
          ))}
        </div>
      )}
    </>
  );
};

export default FileYearList;
