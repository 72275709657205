import axios from "axios";

const SERVER_URL = "https://api.modirecontent.com/api/v1";
// const SERVER_URL = "http://127.0.0.1:5000/api/v1";

export const UpdateMe = (token, data) => {
  const url = `${SERVER_URL}/users/update-profile`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  return axios.patch(url, data);
};

export const UploadPhoto = (token, file) => {
  const url = `${SERVER_URL}/users/upload/photo`;

  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.patch(url, file);
};

export const UpdatePassword = (token, data) => {
  const url = `${SERVER_URL}/users/Update-password`;
  console.log(token);
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.post(url, data);
};

export const GetContentExpert = (token, name) => {
  const url = `${SERVER_URL}/users/content-experts`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.post(url, { search: name });
};

export const GetContentManager = (name, token) => {
  const url = `${SERVER_URL}/users/content-managers`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.post(url, { search: name });
};

export const GetUsers = (token, data) => {
  const url = `${SERVER_URL}/users`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.post(url, {
    user: data.name,
    content: data.content,
    userType: data.userType,
  });
};

export const getAllUser = (page, qauery, token) => {
  const url = `${SERVER_URL}/users?page=${page}${qauery} `;
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  return axios.get(url);
};
