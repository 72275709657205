const Message = ({ data }) => {


    const messageViewHandler = () => {
        let name = "کاربر";
        let mainCss = "";
        let containerCss = "";

        switch (data.role) {
          case "Customer":
            name = "Owner";
            mainCss = "flex flex-col gap-2 items-end text-left";
            containerCss = " w-full xl:w-[max-content] bg-opacity-10 bg-project-green p-4 rounded-r-2xl rounded-tl-2xl rounded-bl-md";
    
            break;
    
          case "Content Manager":
            name = "Content Expert";
            mainCss = "flex flex-col gap-2 flex flex-col gap-2 items-center text-center";
            containerCss = "w-full xl:w-[max-content] bg-project-cyan bg-opacity-10  p-4 rounded-l-2xl rounded-tr-2xl rounded-br-md";
    
            break;
    
          case "Content Writer":
            name = "Content Manager";
            mainCss = "flex flex-col gap-2 items-right text-right";
            containerCss = "w-full xl:w-[max-content] bg-opacity-20 bg-project-orange p-4 rounded-2xl";
            break;
    
          case "Content Editor":
            name = "admin";
            mainCss = "flex flex-col gap-2 items-right text-right";
            containerCss = "w-full xl:w-[max-content] bg-opacity-20 bg-project-red p-4 rounded-2xl";
            break;

          case "Account Manager":
            name = "Support";
            mainCss = "flex flex-col gap-2 items-center text-center";
            containerCss = "w-full xl:w-[max-content] bg-opacity-20 bg-project-red p-4 rounded-2xl";
            break;
          default:
            break;
        }
    
        return (
            <div className={mainCss}>
            <div className={containerCss}>
              <p className="max-w-md ">
                {data.message}
              </p>
            </div>
            <p className="text-primary text-sm p-1 opacity-60">{name}</p>
          </div>
          
        );
      };


  return (
    <>
      {messageViewHandler()}
    </>
  );
};

export default Message;
