import { addNewPost } from "../../services/postServices";
import { useRef, useState } from "react";
import { toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import Spinner from "../../spinner/Spinner";
import { useSelector } from "react-redux";
const AddPost = () => {
  const [name, setName] = useState("");
  const [title, setTitle] = useState("");
  const [slug, setSlug] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState({});
  const [category, setCategory] = useState("");
  const [mainText, setMainText] = useState("");
  const [tags, setTags] = useState("");
  const [date, setDate] = useState("");
  const [status, setStatus] = useState("");
  const [promote, setPromote] = useState("");
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);
  const onChangeHandler = (e, state) => {
    state(e.target.value);
    console.log(e.target.value);
  };
  const fileChangeHandler = (e) => {
    setFile(e.target.files[0]);
  };
  const mainTextHandler = (e) => {
    console.log(e.target.getContent());
    setMainText(e.target.getContent());
  };

  const SubmitHandler = async (e) => {
    //ساعت باید منهای سه ساعت و سی دقیقه بشه
    e.preventDefault();
    const formdata = new FormData();
    formdata.append("name", name);
    formdata.append("title", title);
    formdata.append("slug", slug);
    formdata.append("description", description);
    formdata.append("category", category);
    formdata.append("mainText", mainText);
    formdata.append("tags", tags);
    formdata.append("publishedAt", date);
    formdata.append("status", status);
    formdata.append("promote", promote);
    formdata.append("file", file);
    if (loading) return;
    setLoading(true);
    try {
      const token = user.token;
      if(!token) return toast.error("خطایی رخ داده است.")

      const serverResponse = await addNewPost(formdata,token);
      toast.success("پست جدید ساخته شد", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  return (
    <form className="mt-32 flex flex-col w-[70%] m-auto gap-4 text-right">
      <div className="flex flex-col md:flex-row gap-1">
        <lable htmlFor="name" className=" basis-[30%]">
          نام نوشته:
        </lable>
        <input
          type="text"
          id="name"
          value={name}
          onChange={(e) => onChangeHandler(e, setName)}
          className=" basis-[70%] outline-none border-none bg-gray-50 p-2 rounded"
          placeholder="اسم پست را درج کنید"
        />
      </div>
      <div className="flex flex-col md:flex-row gap-1">
        <lable htmlFor="title" className=" basis-[30%]">
          تایتل:{" "}
        </lable>
        <input
          type="text"
          id="title"
          value={title}
          onChange={(e) => onChangeHandler(e, setTitle)}
          className=" basis-[70%] outline-none border-none bg-gray-50 p-2 rounded"
          placeholder=" تایتل را درج کنید"
        />
      </div>
      <div className="flex flex-col md:flex-row gap-1">
        <lable htmlFor="slug" className=" basis-[30%]">
          نامک:
        </lable>
        <input
          type="text"
          id="slug"
          value={slug}
          onChange={(e) => onChangeHandler(e, setSlug)}
          className=" basis-[70%] outline-none border-none bg-gray-50 p-2 rounded"
          placeholder="  نامک را وارد کنید"
        />
      </div>
      <div className="flex flex-col md:flex-row gap-1">
        <lable htmlFor="description" className=" basis-[30%]">
          دسکیریپشن:
        </lable>
        <input
          type="text"
          id="description"
          value={description}
          onChange={(e) => onChangeHandler(e, setDescription)}
          className=" basis-[70%] outline-none border-none bg-gray-50 p-2 rounded"
          placeholder="توضیحات متا"
        />
      </div>
      <div className="flex flex-col md:flex-row gap-1">
        <lable htmlFor="file" className=" basis-[30%]">
          عکس شاخص:
        </lable>
        <input
          type="file"
          id="file"
          onChange={fileChangeHandler}
          className=" basis-[70%] outline-none border-none bg-gray-50 p-2 rounded"
          placeholder=" عکس شاخص را انتخاب کنید"
        />
      </div>
      <div className="flex flex-col md:flex-row gap-1">
        <lable htmlFor="category" className=" basis-[30%]">
          دسته بندی:
        </lable>
        <input
          type="text"
          id="category"
          value={category}
          onChange={(e) => onChangeHandler(e, setCategory)}
          className=" basis-[70%] outline-none border-none bg-gray-50 p-2 rounded"
          placeholder=" دسته بندی را انتخاب کنید"
        />
      </div>

      <div className="flex flex-col md:flex-row gap-1">
        <lable htmlFor="tags" className=" basis-[30%]">
          تگ های مرتبط:
        </lable>
        <input
          type="text"
          id="tags"
          value={tags}
          onChange={(e) => onChangeHandler(e, setTags)}
          className=" basis-[70%] outline-none border-none bg-gray-50 p-2 rounded"
          placeholder="تگ هار مرتبط را وارد کنید"
        />
      </div>
      <div className="flex flex-col md:flex-row gap-1">
        <lable htmlFor="status" className=" basis-[30%]">
          وضعیت محتوا:
        </lable>
        <select
          name="Status"
          value={status}
          onChange={(e) => onChangeHandler(e, setStatus)}
          id="status"
          className=" basis-[70%] outline-none border-none bg-gray-50 p-2 rounded"
        >
          <option value="public">انتشار محتوا</option>
          <option value="private">پیش نویس</option>
        </select>
      </div>
      <div className="flex flex-col md:flex-row gap-1">
        <lable htmlFor="promote" className=" basis-[30%]">
          برجسته:
        </lable>
        <select
          name="promote"
          id="promote"
          value={promote}
          onChange={(e) => onChangeHandler(e, setPromote)}
          className=" basis-[70%] outline-none border-none bg-gray-50 p-2 rounded"
        >
          <option value="true">برجسته</option>
          <option value="false">عادی</option>
        </select>
      </div>
      <div className="flex flex-col md:flex-row gap-1">
        <lable htmlFor="date" className=" basis-[30%]">
          تاریخ انتشار:
        </lable>
        <input
          type="datetime-local"
          id="date"
          value={date}
          onChange={(e) => onChangeHandler(e, setDate)}
          className=" basis-[70%] outline-none border-none bg-gray-50 p-2 rounded "
          placeholder=" تاریخ انتشار را وارد کنید"
        />
      </div>
      <div className="flex flex-col gap-4">
        <lable htmlFor="mainText">متن اصلی:</lable>

        <Editor
          apiKey="tg159y3tkp8k9mkefa1jer7k8lv2s67ruab8x105e1k9rzeh"
          onChange={(e) => mainTextHandler(e)}
          initialValue=""
          init={{
            branding: false,
            directionality: "rtl",
            height: 400,
            menubar: true,
            plugins:
              "image print preview paste searchreplace autolink directionality visualblocks visualchars fullscreen image link media template codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern",
            toolbar:
              " formatselect | bold italic underline strikethrough | forecolor backcolor blockquote | link image media | alignleft aligncenter alignright alignjustify | numlist bullist outdent indent | removeformat",
            image_advtab: true,
          }}
        />

        <button
          disabled={loading}
          className="p-2 bg-project-cyan text-white rounded flex gap-1 justify-center items-center"
          onClick={SubmitHandler}
        >
          <span>ساخت پست جدید</span>
          {loading ? <Spinner /> : ""}
        </button>
      </div>
    </form>
  );
};

export default AddPost;
