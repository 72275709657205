import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { getMyProject, getMyProjects } from "../../services/projectServices";
import Spinner from "../../spinner/Spinner";
import ContentContainer from "../contents/ContentsContainer";
import Pagination from "../includes/Pagination";
import EditProject from "./EditProject";

const Project = () => {
  const [page, setPage] = useState(1);
  const [openEdit, setOpenEdit] = useState(false);
  const [loading, setLoading] = useState(true);
  const [forceUpdate, setForceUpdate] = useState(false);
  
  const [results, setResults] = useState(0);
  
  const user = useSelector((state) => state.user);
  
  const [searchName, setSName] = useState("");
  const [searchStatus, setSStatus] = useState("");
  
  const [projects, setProjects] = useState([]);
  let searchstr = `page=${page}`;

  const SearchHandler = () => {
    if (page > 0) {
      searchstr = `page=${page}`;
    } else if (page < 0) {
      searchstr = searchstr + `page=1`;
    }
    if (searchName != "") searchstr = searchstr + `&name=${searchName}`;
    if (searchStatus != "") searchstr = searchstr + `&status=${searchStatus}`;

    fetchProject();
  };

  const { id } = useParams();
  const [project, setProject] = useState({
    name: "",
    siteName: "",
  });
  const [contents, setContents] = useState([]);

  useEffect(() => {
    fetchProject();
    getProjects();
  }, [page, user, forceUpdate]);

  const fetchProject = async () => {
    try {
      setLoading(true);
      const token = user.token;
      if (!token) return;
      const { data } = await getMyProject(token, id, searchstr);
      setProject(data.project);

      setContents(data.contents);
      setLoading(false);
      setResults(data.results);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };

  const getProjects = async () => {
    try {
      const token = user.token;
      if (!token) return;

      const { data } = await getMyProjects(token, "fields=name,id");
      setProjects(data.projects);
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
  const onChangeHandler = (e, state) => {
    state(e.target.value);
  };

  const forceUpdateFunc = () => {
    setForceUpdate(!forceUpdate);
  };
  return (
    <div
      className={`container  px-4 md:px-20 md:py-12  py-32 flex flex-col justify-cebter items-center`}
    >
      {loading ? <Spinner /> : <></>}

      {openEdit ? (
        <EditProject

          project={project}
          forceUpdate={forceUpdate}
          setForceUpdate={setForceUpdate}
          setOpenEdit={setOpenEdit}
          id={id}
        />
      ) : (
        ""
      )}
      <div
        className={`bg-white py-2 px-1 rounded lg:flex items-center justify-around grid sm:grid-cols-2 gap-4 grid-cols-1 mb-6 w-full ${
          openEdit || loading ? "blur-sm" : ""
        }`}
      >
        <div className="flex items-center gap-4 justify-center ">
          <p>نام پروژه:</p>
          <p>{project.name}</p>
        </div>
        <div className="flex items-center gap-4 justify-center">
          <p>آدرس سایت:</p>
          <p>{project.siteName}</p>
        </div>
        <div className="flex items-center gap-4 justify-center">
          <p>تعداد محتوا ها:</p>
          <p>{results}</p>
        </div>
        <div className="flex items-center gap-4 justify-center">
          <button disabled={openEdit || loading ? true : false}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M9 13.5l3 3m0 0l3-3m-3 3v-6m1.06-4.19l-2.12-2.12a1.5 1.5 0 00-1.061-.44H4.5A2.25 2.25 0 002.25 6v12a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18V9a2.25 2.25 0 00-2.25-2.25h-5.379a1.5 1.5 0 01-1.06-.44z"
              />
            </svg>
          </button>
          <button
            disabled={openEdit || loading ? true : false}
            onClick={() => {
              setOpenEdit(true);
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 "
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
              />
            </svg>
          </button>
        </div>
      </div>
      <div
        className={`flex gap-4 mb-6 w-full ${
          openEdit || loading ? "blur-sm" : ""
        }`}
      >
        <p className="font-bold text-title">توضیحات محتوا:</p>
        <p>{project.description}</p>
      </div>
      <hr className="opacity-60" />

      <div
        className={`grid grid-cols-7  w-full p-2 rounded bg-white mb-4 gap-2 ${
          openEdit || loading ? "blur-sm" : ""
        }`}
      >
        <div className="col-span-7 sm:col-span-3  lg:col-span-3 flex items-center gap-2">
          <p>نام:</p>
          <input
            readOnly={openEdit || loading}
            className="p-2 w-full lg:w-auto xl:w-[300px] text-primary outline-none border-none rounded bg-slate-100"
            placeholder="نام محتوا را وارد کنید"
            value={searchName}
            onChange={(e) => onChangeHandler(e, setSName)}
          />
        </div>
        <div className="col-span-7 sm:col-span-4 lg:col-span-3 flex items-center gap-2">
        
         
        </div>
        <button
        disabled={openEdit || loading}
          className="col-span-7 lg:col-span-1 px-[10px] py-[2px] bg-project-green text-white rounded"
          onClick={SearchHandler}
        >
          جستجو
        </button>
      </div>

      <ContentContainer
        userRole={"customer"}
        loading={loading}
        projects={projects}
        contents={contents}
        blur={openEdit}
        forceUpdate={forceUpdateFunc}
      />

      <Pagination
        page={page}
        limit={10}
        results={results}
        setPage={setPage}
        loading={loading}
        blur={openEdit}
      />
    </div>
  );
};

export default Project;
