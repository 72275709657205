import { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { toast } from "react-toastify";
import { imageUpload } from "../services/fileServices";
import Spinner from "../spinner/Spinner";

const FileManager = () => {
  const [file, setFile] = useState();
  const [loading, setLoading] = useState(false);
  const [event, setEvent] = useState();
  const user = useSelector((state) => state.user);
  const fileChangeHandler = (e) => {
    setFile(e.target.files[0]);
    setEvent(e);
  };
  const fileUpload = async (e) => {
    e.preventDefault();
    const formdata = new FormData();
    try {
      if (!file) {

  
        return toast.error("لطفا ابتدا فایلی را انتخاب کنید");
      }
      setLoading(true);
      const token = user.token;
      if(!token) return toast.error("خطایی رخ داده است.")
      formdata.append("file", file);
      await imageUpload(formdata,token);
      event.target.value = null;
      toast.success("فایل آپلود شد", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      setLoading(false);
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
      setLoading(false);
    }
  };

  return (
    <div className="container px-4 md:px-20 md:py-12  py-32 flex flex-col items-center ">
      <div className=" w-full p-2 rounded bg-white mb-4">
        <div className="flex  rounded overflow-hidden max-w-sm">
          <input
            className=" w-full bg-background  p-1 outline-none border-none"
            type="file"
            onChange={fileChangeHandler}
            placeholder="عکس مورد نظر را انتخاب کنید"
          />
          <button
            onClick={fileUpload}
            disabled={loading}
            className="bg-project-green text-white px-1"
          >
            {loading ? <Spinner /> : <p>آپلود</p>}
          </button>
        </div>
      </div>

      <Outlet />
    </div>
  );
};

export default FileManager;
