import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import { deletePost, getAllPosts } from "../../services/postServices";
import moment from "moment-jalaali";
import { toast } from "react-toastify";
import Pagination from "../includes/Pagination";
import Spinner from "../../spinner/Spinner";
import { useSelector } from "react-redux";
moment().format("jYYYY/jM/jD");

const Posts = () => {
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [results, setResults] = useState(0);
  const [posts, setPosts] = useState([]);
  const [forceUpdate, setForceUpdate] = useState(false);
  const user = useSelector((state) => state.user);
  useEffect(() => {
    getPosts();
  }, [page, forceUpdate]);

  const DeletePost = async (postid) => {
    try {
      const token = user.token;
      if(!token) return toast.error("خطایی رخ داده است.")

      const data = await deletePost(postid,token);
      if (data.data.status === "201") {
        toast.success("پست مورد نظر حذف شد", {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
        });
        setForceUpdate(!forceUpdate);
      }
    } catch (error) {
      toast.error(error.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };
  const dateConverter = (date) => {
    const m = moment(date, "YYYY/M/D HH:mm").format("jYYYY/jM/jD ساعت: HH:mm");
    // const d = new Date(date)
    return `${m}`;
  };
  const getPosts = async () => {
    try {
      setLoading(true);
      const token = user.token;
      if(!token) return toast.error("خطایی رخ داده است.")

      const { data } = await getAllPosts(page,token);
      setResults(data.data.results);
      setPosts(data.data.data);

      setLoading(false);
    } catch (e) {
      setLoading(false);
      toast.error(e.response.data.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
      });
    }
  };

  return (
    <div className="container px-4 md:px-20 md:py-12  py-32 flex flex-col ">
      <div className="flex items-end justify-between mb-8 flex-wrap gap-4">
        <div className="">
          <h1>نوشته ها</h1>
          <div className="flex mt-2  gap-1">
            <Link to="/">داشبورد</Link>/
            <p className="text-project-orange">نوشته ها</p>
          </div>
        </div>
        <div className="">
          <Link
            className="p-1 bg-project-cyan text-white rounded"
            to={"/posts/add-new"}
          >
            ساخت پست جدید
          </Link>
        </div>
      </div>
      <div className="hidden lg:grid grid-cols-7 items-center p-2 rounded  gap-2 bg-white mb-4 ">
        <div className="col-span-3 text-center">
          <p className="text-sm lg:text-base">نام نوشته</p>
        </div>

        <div className="col-span-1 text-center">
          <p>دسته بندی</p>
        </div>
        <div className="col-span-1 text-center">
          <p>تاریخ درج</p>
        </div>
        <div className="col-span-1 text-center">
          <p>وضعیت</p>
        </div>
        <div className="col-span-1 text-center">
          <p>عملیات‌ها</p>
        </div>
      </div>

      <>
        {loading ? (
          <Spinner />
        ) : (
          <>
            {posts.length > 0 ? (
              <div className="flex flex-col w-full gap-4">
                {/* loop */}
                {posts.map((el) => (
                  <div className="grid grid-cols-8 lg:grid-cols-7 items-center bg-white p-2 rounded  gap-4  justify-center">
                    <div className="col-span-8  lg:col-span-3 flex justify-center gap-4">
                      <p className="lg:hidden w-[150px]">نام نوشته:</p>
                      <p className="px-2 py-1 bg-project-green bg-opacity-40 text-project-green lg:text-title lg:bg-transparent rounded w-full text-center">
                        {el.name}
                      </p>
                    </div>
                    <div className="col-span-8  lg:col-span-1 flex justify-center gap-4 mb-4 lg:mb-0 ">
                      <p className="lg:hidden w-[150px]">دسته بندی:</p>
                      <p className="px-2 py-1 bg-project-cyan bg-opacity-25 text-project-cyan rounded w-full text-center">
                        {el.category}
                      </p>
                    </div>
                    <div className="col-span-8  justify-start sm:justify-center  md:col-span-8 lg:col-span-1 text-center flex items-center gap-4 ">
                      <p className="lg:hidden w-[200px] text-right">
                        تاریخ درج:
                      </p>
                      <p className="text-left w-full sm:text-center">
                        {dateConverter(el.publishedAt)}
                      </p>
                    </div>
                    <div className="col-span-8 sm:col-span-4 justify-start sm:justify-center   lg:col-span-1 text-center flex items-center gap-4 ">
                      <p className="lg:hidden w-[200px] text-right"> وضعیت:</p>
                      <p className="text-left w-full sm:text-center">
                        <span
                          className={
                            el.status === "private"
                              ? "bg-yellow-400 text-gray-50  rounded-md px-2 "
                              : "bg-green-400 text-gray-50  rounded-md px-2"
                          }
                        >
                          {el.status}
                        </span>
                      </p>
                    </div>
                    <div className="col-span-8 sm:col-span-4 justify-start sm:justify-center   lg:col-span-1 text-center flex items-center gap-4 ">
                      <p className="lg:hidden w-[200px] text-right">
                        {" "}
                        عملیات‌ها:
                      </p>

                      <div className="flex w-full items-center justify-end md:justify-center">
                        <Link
                          to={`/posts/edit-post/${el._id}`}
                          className="text-gray-400 hover:text-project-green mx-2 transition duration-[.3s]"
                          title="ویرایش نوشته"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                            />
                          </svg>
                        </Link>
                        <button
                          onClick={() => DeletePost(el._id)}
                          disabled={loading}
                          title="حذف"
                          to="#"
                          className="text-gray-400 hover:text-project-red transition duration-[.3s] ml-2"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 24 24"
                            strokeWidth={1.5}
                            stroke="currentColor"
                            className="w-6 h-6"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0"
                            />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <p className="w-full text-center">محتوایی یافت نشد</p>
            )}
          </>
        )}
      </>
      <Pagination
        page={page}
        limit={10}
        results={results}
        setPage={setPage}
        loading={loading}
      />
    </div>
  );
};

export default Posts;
