import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  sellTeamUpdateContent,
  updateMany,
  statusHandler as managerStatusHandler,
} from "../../../services/contentServices";
import { GetContentManager, GetUsers } from "../../../services/userServices";
import Spinner from "../../../spinner/Spinner";

const AccountManagerEditDialog = ({ content, id, forceUpdate }) => {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("لطفا منتظر بمانید");

  const user = useSelector((state) => state.user);

  // content users states
  const [contentManager, setContentManager] = useState("");
  const [writer, setWriter] = useState("");
  const [editor, setEditor] = useState("");

  const [designManager, setDesignManager] = useState("");
  const [designer, setDesigner] = useState("");
  // users list states
  const [contentManagers, setContentManagers] = useState([]);
  const [writers, setWriters] = useState([]);
  const [editors, setEditors] = useState([]);

  const [designManagers, setDesignManagers] = useState([]);
  const [designers, setDesigners] = useState([]);

  // search query state
  const [CMQuery, setCMQuery] = useState("");
  const [DMQuery, setDMQuery] = useState("");
  const [WQuery, setWQuery] = useState("");
  const [EQuery, setEQuery] = useState("");
  const [DQuery, setDQuery] = useState("");

  // content informations

  const [keywords, setKeywords] = useState("");
  const [description, setDescription] = useState("");
  const [subject, setSubject] = useState("");
  const [contentLink, setContentLink] = useState("");
  const [dueDate, setDueDate] = useState("");
  const [finalWordCount, setFinalWordCount] = useState(0);

  // content statuses
  const [status, setStatus] = useState("");

  const [imageStatus, setImageStatus] = useState("");

  const [imageNewStatus, setImageNewStatus] = useState("");

  const [newStatus, setNewStatus] = useState("");
  const regex = /(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2})/;

  useEffect(() => {
    setSubject(content.subject || "");
    setKeywords(content.keywords || "");
    setDescription(content.description || "");
    setContentLink(content.contentLink || "");
    setFinalWordCount(content.finalWordCount);
    setWriter(content.writer[0] ? content.writer[0]._id : "");
    setContentManager(
      content.contentManager[0] ? content.contentManager[0]._id : ""
    );
    setEditor(content.editor[0] ? content.editor[0]._id : "");
    setDesignManager(
      content.designManager[0] ? content.designManager[0]._id : ""
    );
    setDesigner(content.designer[0] ? content.designer[0]._id : "");

    if (content.dueDate) {
      const match = content.dueDate.match(regex);
      setDueDate(match ? match[1] : "");
    }
  }, []);

  const searchContentTeam = async (e, setState, state) => {
    e.preventDefault();
    try {
      const token = user.token;
      if (!token)
        return toast.error(
          "خطایی رخ داده است لطفا مجددا وارد اکانت شوید و یا با پشتیبانی تماس بگیرید. "
        );
      const { data } = await GetUsers(token, {
        name: state,
        userType: "contentTeam",
        content: "",
      });
      console.log(data);
      setState(data.user);
    } catch (error) {
      setLoading(false);
      setContentManagers([]);
      toast.error(error.response.data.message);
    }
  };

  const searchDesignTeam = async (e, setState, state) => {
    e.preventDefault();
    try {
      const token = user.token;
      if (!token)
        return toast.error(
          "خطایی رخ داده است لطفا مجددا وارد اکانت شوید و یا با پشتیبانی تماس بگیرید. "
        );

      const { data } = await GetUsers(token, {
        name: DMQuery,
        userType: "designTeam",
        content: "",
      });
      setState(data.user);
    } catch (error) {
      setLoading(false);
      setDesignManagers([]);
      toast.error(error.response.data.message);
    }
  };

  const onChangeHandler = (e, state) => {
    state(e.target.value);
    console.log(e.target.value);
  };

  // const statusHandler = async (e) => {
  //   e.preventDefault();
  //   try {
  //     if (newStatus === "")
  //       return toast.info("لطفا ابتدا یک وضعیت انتخاب کنید");
  //     const token = user.token;
  //     if (!token) return;

  //     const { data } = await managerStatusHandler(token, {
  //       status: newStatus,
  //       id: id,
  //     });

  //     toast.success(data.message);
  //     setStatus(newStatus);
  //   } catch (error) {
  //     toast.error(error.response.data.message);
  //   }
  // };
  // const imgStatusHandler = async (e) => {
  //   e.preventDefault();
  //   try {
  //     if (imageNewStatus === "")
  //       return toast.info("لطفا ابتدا یک وضعیت انتخاب کنید");
  //     const token = user.token;
  //     if (!token) return;
  //     const { data } = await managerStatusHandler(token, {
  //       status: imageNewStatus,
  //       id: id,
  //     });
  //     toast.success(data.message);
  //     setStatus(newStatus);
  //   } catch (error) {
  //     toast.error(error.response.data.message);
  //   }
  // };

  const updateContent = async (e) => {
    e.preventDefault();
    try {
      console.log({
        updateState: "accountManager",
        contentsID: [{ _id: id }],
        update: {
          status: newStatus,
          imageStatus: imageNewStatus,
          contentTeam: {
            writer: writer,
            editor: editor,
            contentManager: contentManager,
          },
          designTeam: {
            designer,
            designManager,
          },
          keywords,
          description,
          subject,
          contentLink,
          dueDate: dueDate === "" ? "" : `${dueDate}Z`,
          finalWordCount,
        },
      });

      const token = user.token;
      if (!token) return toast.error("لطفا مجددا وارد شوید.");
      setLoading(true);

      const { data } = await updateMany(token, {

        updateState: "accountManager",
        contentsID: [{ _id: id }],
        update: {
          status: newStatus,
          imageStatus: imageNewStatus,
          contentTeam: {
            writer: writer,
            editor: editor,
            contentManager: contentManager,
          },
          designTeam: {
            designer,
            designManager,
          },
          keywords,
          description,
          subject,
          contentLink,
          dueDate: dueDate === "" ? "" : `${dueDate}Z`,
          finalWordCount,
        },
      });
      toast.success("محتوا با موفقیت آپدیت شد.");
      setLoading(false);

      forceUpdate();
    } catch (error) {
      setLoading(false);

      toast.error(error.response.data.message);
    }
  };

  return (
    <>
      {loading ? (
        <div className="absolute z-10 text-2xl">
          <Spinner />
        </div>
      ) : (
        ""
      )}
      <div
        className={` grid grid-cols-12 gap-y-3 lg:gap-x-3 lg:gap-y-6 w-full${
          loading ? "blur-md" : ""
        }`}
      >
        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start sm:items-center ">
          <p className="w-[180px]">موضوع:</p>

          <input
            readOnly={loading}
            value={subject}
            onChange={(e) => onChangeHandler(e, setSubject)}
            type="text"
            className=" w-full sm:w-[calc(100%-180px)] bg-background rounded p-1 outline-none border-none"
          />
        </div>
        {/* content users start */}

        {/* content content manager */}

        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[180px]">جستجو مدیر محتوا:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <input
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setCMQuery)}
              type="text"
              placeholder={
                content.contentManager && content.contentManager[0]
                  ? content.contentManager[0].name ||
                    content.contentManager[0].phoneNumber
                  : "نام یا شماره مدیر محتوا را وارد کنید."
              }
              className="w-full bg-transparent p-1 outline-none border-none"
            />
            <button
              className="bg-project-cyan text-white hover:animate-pulse  px-2 py-1  "
              onClick={(e) => searchContentTeam(e, setContentManagers, CMQuery)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                dataSlot="icon"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col items-start sm:items-center sm:flex-row    ">
          <p className="w-[180px]">انتخاب مدیر محتوا:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            {contentManagers.length < 0 ? (
              <input
                readOnly
                type="text"
                value={contentManager}
                className="w-full bg-transparent p-1 outline-none border-none"
              />
            ) : (
              <select
                onChange={(e) => {
                  onChangeHandler(e, setContentManager);
                }}
                name=""
                id=""
                className="outline-none border-none w-full p-2 rounded bg-background"
              >
                <option value="" disabled selected>
                  گزینه مورد نظر را انتخاب کنید
                </option>{" "}
                {contentManagers.map((el) => (
                  <option value={`${el._id}`}>
                    {el.name ? el.name : el.phoneNumber}
                  </option>
                ))}
              </select>
            )}
          </div>
        </div>

        {/* content content editor */}
        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[180px]">جستجو ویراستار:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <input
              placeholder={
                content.editor && content.editor[0]
                  ? content.editor[0].name || content.editor[0].phoneNumber
                  : "نام یا شماره ویراستار را وارد کنید."
              }
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setEQuery)}
              type="text"
              className="w-full bg-transparent p-1 outline-none border-none"
            />
            <button
              className="bg-project-cyan text-white hover:animate-pulse  px-2 py-1  "
              onClick={(e) => searchContentTeam(e, setEditors, EQuery)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                dataSlot="icon"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col items-start sm:items-center sm:flex-row    ">
          <p className="w-[180px]">انتخاب ویراستار:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <select
              onChange={(e) => {
                onChangeHandler(e, setEditor);
              }}
              name=""
              id=""
              className="outline-none border-none w-full p-2 rounded bg-background"
            >
              <option value="" disabled selected>
                گزینه مورد نظر را انتخاب کنید
              </option>{" "}
              {editors.map((el) => (
                <option value={`${el._id}`}>
                  {el.name ? el.name : el.phoneNumber}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* content content writer */}
        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[180px]">جستجو نویسنده:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <input
              readOnly={loading}
              placeholder={
                content.writer && content.writer[0]
                  ? content.writer[0].name || content.writer[0].phoneNumber
                  : "نام یا شماره نویسنده را وارد کنید."
              }
              onChange={(e) => onChangeHandler(e, setWQuery)}
              type="text"
              className="w-full bg-transparent p-1 outline-none border-none"
            />
            <button
              className="bg-project-cyan text-white hover:animate-pulse  px-2 py-1  "
              onClick={(e) => searchContentTeam(e, setWriters, WQuery)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                dataSlot="icon"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col items-start sm:items-center sm:flex-row    ">
          <p className="w-[180px]">انتخاب نویسنده:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <select
              onChange={(e) => {
                onChangeHandler(e, setWriter);
              }}
              name=""
              id=""
              className="outline-none border-none w-full p-2 rounded bg-background"
            >
              <option value="" disabled selected>
                گزینه مورد نظر را انتخاب کنید
              </option>{" "}
              {writers.map((el) => (
                <option value={`${el._id}`}>
                  {el.name ? el.name : el.phoneNumber}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* content content design Manager */}
        <div className="flex gap-2  col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[180px]">جستجو مدیر مدیا:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <input
              placeholder={
                content.designManager && content.designManager[0]
                  ? content.designManager[0].name ||
                    content.designManager[0].phoneNumber
                  : "نام یا شماره مدیر گرافیک را وارد کنید."
              }
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setDMQuery)}
              type="text"
              className="w-full bg-transparent p-1 outline-none border-none"
            />
            <button
              className="bg-project-cyan text-white hover:animate-pulse  px-2 py-1  "
              onClick={(e) => searchDesignTeam(e, setDesignManagers, DMQuery)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                dataSlot="icon"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>
        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col items-start sm:items-center sm:flex-row    ">
          <p className="w-[180px]">انتخاب مدیر مدیا:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <select
              onChange={(e) => {
                onChangeHandler(e, setDesignManager);
              }}
              name=""
              id=""
              className="outline-none border-none w-full p-2 rounded bg-background"
            >
              <option value="" disabled selected>
                گزینه مورد نظر را انتخاب کنید
              </option>{" "}
              {designManagers.map((el) => (
                <option value={`${el._id}`}>
                  {el.name ? el.name : el.phoneNumber}
                </option>
              ))}
            </select>
          </div>
        </div>
        {/* content content designer */}
        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col sm:flex-row items-start sm:items-center   ">
          <p className="w-[180px]">جستجو گرافیست:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <input
              placeholder={
                content.designer && content.designer[0]
                  ? content.designer[0].name || content.designer[0].phoneNumber
                  : "نام یا شماره گرافیست را وارد کنید."
              }
              readOnly={loading}
              onChange={(e) => onChangeHandler(e, setDQuery)}
              type="text"
              className="w-full bg-transparent p-1 outline-none border-none"
            />
            <button
              className="bg-project-cyan text-white hover:animate-pulse  px-2 py-1  "
              onClick={(e) => searchDesignTeam(e, setDesigners, DQuery)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                dataSlot="icon"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
                />
              </svg>
            </button>
          </div>
        </div>

        <div className="flex gap-2 col-span-12 lg:col-span-6 flex-col items-start sm:items-center sm:flex-row    ">
          <p className="w-[180px]">انتخاب گرافیست:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <select
              onChange={(e) => {
                onChangeHandler(e, setDesigner);
              }}
              name=""
              id=""
              className="outline-none border-none w-full p-2 rounded bg-background"
            >
              <option value="" disabled selected>
                گزینه مورد نظر را انتخاب کنید
              </option>{" "}
              {designers.map((el) => (
                <option value={`${el._id}`}>
                  {el.name ? el.name : el.phoneNumber}
                </option>
              ))}
            </select>
          </div>
        </div>

        {/* content users end */}
        <div className="flex gap-2  col-span-12 flex-col lg:col-span-6 sm:flex-row items-start sm:items-center ">
          <p className="w-[180px]"> مهلت تحویل:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <input
              type="datetime-local"
              readOnly={loading}
              value={dueDate}
              onChange={(e) => onChangeHandler(e, setDueDate)}
              className="w-full bg-transparent p-1 outline-none border-none"
            />
          </div>
        </div>
        <div className="flex gap-2  col-span-12 flex-col lg:col-span-6 sm:flex-row items-start sm:items-center ">
          <p className="w-[180px]">تعداد کلمات:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <input
              type="number"
              readOnly={loading}
              value={finalWordCount}
              onChange={(e) => onChangeHandler(e, setFinalWordCount)}
              className="w-full bg-transparent p-1 outline-none border-none"
            />
          </div>
        </div>
        <div className="flex gap-2  col-span-12 flex-col lg:col-span-6 sm:flex-row items-start sm:items-center ">
          <p className="w-[180px]">وضعیت محتوا:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] ">
            <select
              disabled={loading}
              onChange={(e) => {
                onChangeHandler(e, setNewStatus);
              }}
              type="text"
              className="w-full  bg-background rounded p-1 outline-none border-none"
            >
              <option value="item-1" selected disabled>
                وضعیت مورد نظر خود را انتخاب کنید.
              </option>
              <option value="Assing to be Written">ارسال برای نگارش</option>
              <option value="Assign to Content Manager">ارسال برای مدیر محتوا</option>

              <option value="Need rewriting">نیاز به بازنویسی</option>
              <option value="Written">نوشته شده</option>
              <option value="Editor Approved">تایید ویراستار</option>
              <option value="Manager does not approved">
                عدم تایید مدیر محتوا
              </option>
              <option value="Manager approved">تایید مدیر محتوا</option>

              <option value="Customer Approved">تایید مشتری</option>
              <option value="Customer not Accepted">عدم تایید مشتری</option>
              <option value="Account Manager does not approved">
                عدم تایید مدیر محتوا
              </option>
            </select>

           
          </div>
        </div>
        <div className="flex gap-2  col-span-12 flex-col lg:col-span-6 sm:flex-row items-start sm:items-center ">
          <p className="w-[180px]">وضعیت عکس:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] ">
            <select
              disabled={loading}
              onChange={(e) => {
                onChangeHandler(e, setImageNewStatus);
              }}
              type="text"
              className="w-full  bg-background rounded p-1 outline-none border-none"
            >
              <option value="item-1" selected disabled>
                وضعیت مورد نظر خود را انتخاب کنید.
              </option>
              <option value="Assing to Design Manager">
                اساین شده برای مدیر مدیا
              </option>
              <option value="Assing to be Designed">
                ارسال برای پروسه طراحی
              </option>
              <option value="Images Assigned to be Designed">
                ارسال برای گرافیست
              </option>
              <option value="Manager has not approved Images">
                عدم تایید عکس مدیر محتوا
              </option>
              <option value="Images require redesigning">
                عکس نیاز به باز طراحی دارد
              </option>
              <option value="Images designed">عکس ها طراحی شد</option>
              <option value="Images redesigned">عکس مجدد طراحی شده</option>
              <option value="Design Manager Accepted">
                تایید مدیر گرافیک{" "}
              </option>
              <option value="Manager approved images">
                تایید عکس مدیر محتوا
              </option>
            </select>

            
          </div>
        </div>
        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start  sm:items-center ">
          <p className="w-[180px]">کلمات کلیدی:</p>
          <input
            readOnly={loading}
            value={keywords}
            onChange={(e) => onChangeHandler(e, setKeywords)}
            type="text"
            className="w-full sm:w-[calc(100%-180px)] bg-background rounded p-1 outline-none border-none"
          />
        </div>
        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start  ">
          <p className="w-[180px]">توضیحات :</p>
          <textarea
            value={description}
            onChange={(e) => onChangeHandler(e, setDescription)}
            type="text"
            className="w-full sm:w-[calc(100%-180px)] bg-background rounded p-1 outline-none border-none"
          />
        </div>
        <div className="flex gap-2 col-span-12 flex-col sm:flex-row items-start  sm:items-center  ">
          <p className="w-[180px]"> لینک محتوا:</p>
          <div className="flex w-full sm:w-[calc(100%-180px)] bg-background shadow-regular rounded overflow-hidden">
            <input
              type="text"
              readOnly={loading}
              value={contentLink}
              onChange={(e) => onChangeHandler(e, setContentLink)}
              className="w-full bg-transparent p-1 outline-none border-none"
            />
          </div>
        </div>
        <div className="flex gap-4 items-center">
          <button
            onClick={updateContent}
            className="bg-project-green text-white hover:animate-pulse  px-4 py-1 rounded "
          >
            ویرایش
          </button>
        </div>
      </div>
    </>
  );
};

export default AccountManagerEditDialog;
