import { useContext } from "react";
import { ViewContext } from "../../context/ViewContext";
import Cookies from "universal-cookie";
import { store } from "../../app/store";
import { setUser } from "../../features/user/userSlice";
import { useState } from "react";
import { Link, Navigate } from "react-router-dom";
import { useEffect } from "react";

import { useSelector } from "react-redux";

const SideBar = () => {
  const { toggle } = useContext(ViewContext);
  const [redirect, setRedirect] = useState(false);
  const cookies = new Cookies();
  const user = useSelector((state) => state.user);
  
  const exitHandler = (e) => {
    e.preventDefault();
    cookies.remove("auth");
    store.dispatch(
      setUser({
        name: "",
        token: "",
        photo: "",
        id: "",
        role: "",
      })
    );
    setRedirect(true);
  };

  useEffect(() => {}, [user]);

  return (
    <div
      className={`${
        toggle ? "w-[100px]  " : " md:w-[288px] xl:w-[250px]"
      } py-6 bg-[#f7fafd]  hidden h-full transition-all	 ease-in-out duration-[.3s] md:flex flex-col gap-8`}
    >
      {redirect ? <Navigate to="/auth/login" /> : ""}

      <div className="mb-4 mx-6 flex gap-2 items-center h-14">
        <img
          src="/img/modirecontent.png"
          alt="modirecontent logo"
          className=" w-[70px] "
        />
        <div
          className={`text-right ${
            toggle ? "text-[0]" : "text-base"
          } transition-all ease-in-out duration-[.3s]`}
        >
          <p>مدیر محتوا</p>
          <p>modirecontent</p>
        </div>
      </div>

      <ul className="flex flex-col gap-4 mx-6 ">
        <li className={`w-full`}>
          <Link
            to="/"
            className={`py-1 rounded min-h-[40px]  pr-3 ${
              window.location.pathname === "/"
                ? "bg-project-orange text-white"
                : "bg-white text-alternative"
            } flex gap-2 items-center  font-bold`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6 font-bold"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M2.25 12l8.954-8.955c.44-.439 1.152-.439 1.591 0L21.75 12M4.5 9.75v10.125c0 .621.504 1.125 1.125 1.125H9.75v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21h4.125c.621 0 1.125-.504 1.125-1.125V9.75M8.25 21h8.25"
              />
            </svg>
            <p
              className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                toggle ? "text-[0px]" : "text-base"
              }`}
            >
              داشبورد
            </p>
          </Link>
        </li>
        {user.role === "customer" ? (
          <li className={`w-full`}>
            <Link
              to="/projects"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/projects"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              }  flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M20.25 14.15v4.25c0 1.094-.787 2.036-1.872 2.18-2.087.277-4.216.42-6.378.42s-4.291-.143-6.378-.42c-1.085-.144-1.872-1.086-1.872-2.18v-4.25m16.5 0a2.18 2.18 0 00.75-1.661V8.706c0-1.081-.768-2.015-1.837-2.175a48.114 48.114 0 00-3.413-.387m4.5 8.006c-.194.165-.42.295-.673.38A23.978 23.978 0 0112 15.75c-2.648 0-5.195-.429-7.577-1.22a2.016 2.016 0 01-.673-.38m0 0A2.18 2.18 0 013 12.489V8.706c0-1.081.768-2.015 1.837-2.175a48.111 48.111 0 013.413-.387m7.5 0V5.25A2.25 2.25 0 0013.5 3h-3a2.25 2.25 0 00-2.25 2.25v.894m7.5 0a48.667 48.667 0 00-7.5 0M12 12.75h.008v.008H12v-.008z"
                />
              </svg>
              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                پروژه ها
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "customer" ? (
          <li className={`w-full`}>
            <Link
              to="/contents"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/contents"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M16.862 4.487l1.687-1.688a1.875 1.875 0 112.652 2.652L10.582 16.07a4.5 4.5 0 01-1.897 1.13L6 18l.8-2.685a4.5 4.5 0 011.13-1.897l8.932-8.931zm0 0L19.5 7.125M18 14v4.75A2.25 2.25 0 0115.75 21H5.25A2.25 2.25 0 013 18.75V8.25A2.25 2.25 0 015.25 6H10"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                محتوا ها
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "accountManager" ? (
          <li className={`w-full`}>
            <Link
              to="/account-manager/"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/account-manager/"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                مدیریت محتوا
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}

        {user.role === "contentTeam" ? (
          <li className={`w-full`}>
            <Link
              to="/content-manager"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/content-manager"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M6.75 3v2.25M17.25 3v2.25M3 18.75V7.5a2.25 2.25 0 0 1 2.25-2.25h13.5A2.25 2.25 0 0 1 21 7.5v11.25m-18 0A2.25 2.25 0 0 0 5.25 21h13.5A2.25 2.25 0 0 0 21 18.75m-18 0v-7.5A2.25 2.25 0 0 1 5.25 9h13.5A2.25 2.25 0 0 1 21 11.25v7.5m-9-6h.008v.008H12v-.008ZM12 15h.008v.008H12V15Zm0 2.25h.008v.008H12v-.008ZM9.75 15h.008v.008H9.75V15Zm0 2.25h.008v.008H9.75v-.008ZM7.5 15h.008v.008H7.5V15Zm0 2.25h.008v.008H7.5v-.008Zm6.75-4.5h.008v.008h-.008v-.008Zm0 2.25h.008v.008h-.008V15Zm0 2.25h.008v.008h-.008v-.008Zm2.25-4.5h.008v.008H16.5v-.008Zm0 2.25h.008v.008H16.5V15Z"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                مدیریت محتوا
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "contentTeam" ? (
          <li className={`w-full`}>
            <Link
              to="/content-writer"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/content-writer"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m16.862 4.487 1.687-1.688a1.875 1.875 0 1 1 2.652 2.652L6.832 19.82a4.5 4.5 0 0 1-1.897 1.13l-2.685.8.8-2.685a4.5 4.5 0 0 1 1.13-1.897L16.863 4.487Zm0 0L19.5 7.125"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                نگارش محتوا
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "contentTeam" ? (
          <li className={`w-full`}>
            <Link
              to="/content-editor"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/content-editor"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M15.59 14.37a6 6 0 0 1-5.84 7.38v-4.8m5.84-2.58a14.98 14.98 0 0 0 6.16-12.12A14.98 14.98 0 0 0 9.631 8.41m5.96 5.96a14.926 14.926 0 0 1-5.841 2.58m-.119-8.54a6 6 0 0 0-7.381 5.84h4.8m2.581-5.84a14.927 14.927 0 0 0-2.58 5.84m2.699 2.7c-.103.021-.207.041-.311.06a15.09 15.09 0 0 1-2.448-2.448 14.9 14.9 0 0 1 .06-.312m-2.24 2.39a4.493 4.493 0 0 0-1.757 4.306 4.493 4.493 0 0 0 4.306-1.758M16.5 9a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                ویراستاری
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "designTeam" ? (
          <li className={`w-full`}>
            <Link
              to="/design-manager"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/design-manager"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                مدیریت طراحی ها
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "designTeam" ? (
          <li className={`w-full`}>
            <Link
              to="/designer"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/designer"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9.53 16.122a3 3 0 0 0-5.78 1.128 2.25 2.25 0 0 1-2.4 2.245 4.5 4.5 0 0 0 8.4-2.245c0-.399-.078-.78-.22-1.128Zm0 0a15.998 15.998 0 0 0 3.388-1.62m-5.043-.025a15.994 15.994 0 0 1 1.622-3.395m3.42 3.42a15.995 15.995 0 0 0 4.764-4.648l3.876-5.814a1.151 1.151 0 0 0-1.597-1.597L14.146 6.32a15.996 15.996 0 0 0-4.649 4.763m3.42 3.42a6.776 6.776 0 0 0-3.42-3.42"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                طراحی ها
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "administrator" ? (
          <li className={`w-full`}>
            <Link
              to="/posts"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname.includes("/posts")
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M9 12h3.75M9 15h3.75M9 18h3.75m3 .75H18a2.25 2.25 0 002.25-2.25V6.108c0-1.135-.845-2.098-1.976-2.192a48.424 48.424 0 00-1.123-.08m-5.801 0c-.065.21-.1.433-.1.664 0 .414.336.75.75.75h4.5a.75.75 0 00.75-.75 2.25 2.25 0 00-.1-.664m-5.8 0A2.251 2.251 0 0113.5 2.25H15c1.012 0 1.867.668 2.15 1.586m-5.8 0c-.376.023-.75.05-1.124.08C9.095 4.01 8.25 4.973 8.25 6.108V8.25m0 0H4.875c-.621 0-1.125.504-1.125 1.125v11.25c0 .621.504 1.125 1.125 1.125h9.75c.621 0 1.125-.504 1.125-1.125V9.375c0-.621-.504-1.125-1.125-1.125H8.25zM6.75 12h.008v.008H6.75V12zm0 3h.008v.008H6.75V15zm0 3h.008v.008H6.75V18z"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                نوشته ها
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "administrator" ? (
          <li className={`w-full`}>
            <Link
              to="/files"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/files"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="m9 13.5 3 3m0 0 3-3m-3 3v-6m1.06-4.19-2.12-2.12a1.5 1.5 0 0 0-1.061-.44H4.5A2.25 2.25 0 0 0 2.25 6v12a2.25 2.25 0 0 0 2.25 2.25h15A2.25 2.25 0 0 0 21.75 18V9a2.25 2.25 0 0 0-2.25-2.25h-5.379a1.5 1.5 0 0 1-1.06-.44Z"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                رسانه
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "administrator" ? (
          <li className={`w-full`}>
            <Link
              to="/shop/manual-order"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname.startsWith("/shop")
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                رسانه
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "administrator" ? (
          <li className={`w-full`}>
            <Link
              to="/sell-team-contents/"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname.startsWith("/sell-team-contents")
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M7.5 8.25h9m-9 3H12m-9.75 1.51c0 1.6 1.123 2.994 2.707 3.227 1.129.166 2.27.293 3.423.379.35.026.67.21.865.501L12 21l2.755-4.133a1.14 1.14 0 0 1 .865-.501 48.172 48.172 0 0 0 3.423-.379c1.584-.233 2.707-1.626 2.707-3.228V6.741c0-1.602-1.123-2.995-2.707-3.228A48.394 48.394 0 0 0 12 3c-2.392 0-4.744.175-7.043.513C3.373 3.746 2.25 5.14 2.25 6.741v6.018Z"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                {" "}
                تیم فروش
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}
        {user.role === "customer" ? (
          <li className="w-full">
            <Link
              to="/shop"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/shop"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M13.5 21v-7.5a.75.75 0 01.75-.75h3a.75.75 0 01.75.75V21m-4.5 0H2.36m11.14 0H18m0 0h3.64m-1.39 0V9.349m-16.5 11.65V9.35m0 0a3.001 3.001 0 003.75-.615A2.993 2.993 0 009.75 9.75c.896 0 1.7-.393 2.25-1.016a2.993 2.993 0 002.25 1.016c.896 0 1.7-.393 2.25-1.016a3.001 3.001 0 003.75.614m-16.5 0a3.004 3.004 0 01-.621-4.72L4.318 3.44A1.5 1.5 0 015.378 3h13.243a1.5 1.5 0 011.06.44l1.19 1.189a3 3 0 01-.621 4.72m-13.5 8.65h3.75a.75.75 0 00.75-.75V13.5a.75.75 0 00-.75-.75H6.75a.75.75 0 00-.75.75v3.75c0 .415.336.75.75.75z"
                />
              </svg>

              <p
                className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                فروشگاه
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}

        {user.role === "customer" ? (
          <li className={`w-full`}>
            <Link
              to="/orders"
              className={`py-1 rounded min-h-[40px] pr-3 ${
                window.location.pathname === "/orders"
                  ? "bg-project-orange text-white"
                  : "bg-white text-alternative"
              } flex gap-2 items-center  font-bold`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z"
                />
              </svg>

              <p
                className={`pt-1 transition-all ease-in-out duration-[.3s]  ${
                  toggle ? "text-[0px]" : "text-base"
                }`}
              >
                صورت‌حساب
              </p>
            </Link>
          </li>
        ) : (
          ""
        )}

        <li className="w-full">
          <Link
            to="/profile"
            className={`py-1 w-full rounded min-h-[40px] pr-3 ${
              window.location.pathname === "/profile"
                ? "bg-project-orange text-white"
                : "bg-white text-alternative"
            } flex gap-2 items-center  font-bold`}
          >
            {" "}
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
              />
            </svg>
            <p
              className={`pt-1  transition-all ease-in-out duration-[.3s]  ${
                toggle ? "text-[0px]" : "text-base"
              }`}
            >
              پروفایل
            </p>
          </Link>{" "}
        </li>
        <li className="w-full">
          {" "}
          <button
            onClick={exitHandler}
            className={`py-1 w-full min-h-[40px] rounded text-alternative pr-3 bg-white flex gap-2 items-center  font-bold`}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15m3 0l3-3m0 0l-3-3m3 3H9"
              />
            </svg>

            <p
              className={`pt-1 transition-all ease-in-out duration-[.3s]  ${
                toggle ? "text-[0px]" : "text-base"
              }`}
            >
              خروج
            </p>
          </button>{" "}
        </li>
      </ul>
    </div>
  );
};

export default SideBar;
