import axios from "axios";
import { store } from "../app/store";
import Cookies from "universal-cookie";


const cookies = new Cookies();
const SERVER_URL = "https://api.modirecontent.com/api/v1";
// const SERVER_URL = "http://127.0.0.1:5000/api/v1";

export const addNewPost = (data,token) => {
  const url = `${SERVER_URL}/blog`;
  axios.defaults.headers.common["Authorization"] = `Bearer ${
   token
  }`;

  return axios.post(url, data,token);
};
export const deletePost = (postid,token)=>{
  axios.defaults.headers.common["Authorization"] = `Bearer ${
   token
  }`;

  const url = `${SERVER_URL}/blog/${postid}`;

  return axios.delete(url);
}
export const getAllPosts = (page,token) => {
  axios.defaults.headers.common["Authorization"] = `Bearer ${
   token
  }`;
  const url = `${SERVER_URL}/blog?page=${page,token}`;
  console.log(url)
  return axios.get(url);
};

export const EditaPost = (postid,data,token)=>{
  const url = `${SERVER_URL}/blog/${postid}`;

  axios.defaults.headers.common["Authorization"] = `Bearer ${
   token
  }`;

return axios.patch(url,data)
}

export const GetaPost =(postid,token)=>{
  const url = `${SERVER_URL}/blog/${postid}`;

  axios.defaults.headers.common["Authorization"] = `Bearer ${
   token
  }`;

  return axios.get(url)
}